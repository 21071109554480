import {
    AfterViewInit,
    Component,
    ElementRef,
    OnInit,
    Renderer2,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {Router} from '@angular/router';
import {DeviceHomePage, Marker, N1DeviceHomePage} from './home.model';
import {ApiService} from '@svc/api.service';
import {KuntzeMapStyle} from '@pages/home/map.model';
import {GoogleMap} from '@angular/google-maps';
import MarkerClusterer from '@googlemaps/markerclustererplus';

@Component({
    selector: 'kntz-home-page',
    templateUrl: './home.page.html',
    styleUrls: ['./home.page.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class HomePageComponent implements OnInit, AfterViewInit {
    public mapStyle: google.maps.MapTypeStyle[] = KuntzeMapStyle;
    @ViewChild('devicesContainer') devicesContainer;
    @ViewChild(GoogleMap) map: GoogleMap;

    public deviceGenerationMulti = 0;
    public deviceGenerationN1 = 1;

    // 51.5167,9.9167 -> Germany
    public mapCenter: google.maps.LatLngLiteral = {lat: 51.5167, lng: 9.9167};

    public mapOptions: google.maps.MapOptions = {
        center: this.mapCenter,
        styles: this.mapStyle,
        zoom: 4,
        fullscreenControl: false,
        mapTypeControl: false,
    };
    private markerCluster: MarkerClusterer;

    zIndexCounter = 1000;

    public devices: DeviceHomePage[];
    public n1Devices: N1DeviceHomePage[];
    public markers = {};
    public markersInMarkerClusterFormat = [];

    public devicesInCities = {};
    public cities = [];
    public hiddenCities = {};

    public devicesMeasurements = {};
    // stores true when the /devices request finishes
    public dataLoaded = false;
    // used to unblock the UI when drawing a lot of devices
    public activeCities = {};
    // stores true when all the devices have been added to the DOM
    public deviceDrawingComplete = false;
    // if true, the systems list is filtered
    public filteredSystemsList = false;
    private observers = {};
    private intersectionEvents = {};
    private intersectionEventsTimeouts = {};

    private viewInitDone = false;

    constructor(
        private api: ApiService,
        private elementRef: ElementRef,
        private renderer: Renderer2,
        private router: Router
    ) {
    }

    ngAfterViewInit() {
        this.viewInitDone = true;
        this.markerCluster = new MarkerClusterer(
            this.map.googleMap,
            [],
            {
                //imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'
                styles: [
                    {
                        width: 30,
                        height: 30,
                        className: "custom-clustericon-1",
                    },
                    {
                        width: 30,
                        height: 30,
                        className: "custom-clustericon-2",
                    },
                    {
                        width: 30,
                        height: 30,
                        className: "custom-clustericon-3",
                    },
                ],
                clusterClass: 'custom-clustericon',
            }
        );
    }

    ngOnInit() {
        this.getDevices();
    }

    /**
     * Prepare the marker info box content
     * @param marker
     */
    prepareMarkerInfo(marker) {
        const infos = [];
        for (let idx = 0; idx < marker.items.length; idx++) {
            const m = marker.items[idx];
            if (m.visible === false) {
                continue;
            }

            const info = {
                name: null,
                city: null,
                status: 'offline',
                devices: [],
            };

            const visible = (typeof (m.visible) === 'undefined') || (m.visible === true);

            if (!visible) {
                continue;
            }

            if (m.status === 'online') {
                info.status = 'online';
            }
            info.city = m.city;
            info.name = m.name;
            info.devices = m.devices.sort((a, b) => (a.name > b.name) ? 1 : -1);

            infos.push(info);
        }

        return infos;
    }

    /**
     * Joins the markers by lat + lng, to prevent duplicates on the map
     */
    prepareMarkers(bulkMarkers) {
        const markers = {};
        const precision = 1000;

        for (const marker of bulkMarkers) {
            const latitude = Math.floor(parseFloat(marker.latitude) * precision) / precision;
            const longitude = Math.floor(parseFloat(marker.longitude) * precision) / precision;

            const identifier = `lat=${latitude},lng=${longitude}`;
            if (typeof (markers[identifier]) === 'undefined') {
                markers[identifier] = {
                    latitude: null,
                    longitude: null,
                    position: {lat: latitude, lng: longitude},
                    color: 'green',
                    status: 'online',
                    infos: [],
                    items: [],
                    options: {icon: '../../assets/images/marker_green.png'}
                };
            }

            markers[identifier].items.push(<Marker>marker);

            // if one system is red, we mark everything as red
            if (marker.status === 'offline') {
                markers[identifier].color = 'red';
                markers[identifier].status = 'offline';
            }

            const {latitudeSum, longitudeSum} = markers[identifier].items.reduce(
                (accumulator, item) => {
                    accumulator.latitudeSum += parseFloat(item.latitude);
                    accumulator.longitudeSum += parseFloat(item.longitude);
                    return accumulator;
                },
                {latitudeSum: 0, longitudeSum: 0}
            );

            markers[identifier].latitude = latitudeSum / markers[identifier].items.length;
            markers[identifier].longitude = longitudeSum / markers[identifier].items.length;
            markers[identifier].position = {lat: markers[identifier].latitude, lng: markers[identifier].longitude};
            markers[identifier].infos = this.prepareMarkerInfo(markers[identifier]);
            markers[identifier].options = {icon: `../../assets/images/marker_${markers[identifier].color}.png`};
        }
        return markers;
    }

    /**
     * Converts a marker from internal format to MarkerClusterer format
     * @param location
     */
    convertMarkerToMarkerClustererFormat(location: any): google.maps.Marker {
        const marker: google.maps.Marker = new google.maps.Marker({
            position: {lat: location.latitude, lng: location.longitude},
            icon: location.options.icon,
        });

        const devicesListContainer = this.renderer.createElement('div');
        this.renderer.addClass(devicesListContainer, 'flex');

        if (location.infos[0].devices.length > 0) {
            for (const deviceM of location.infos[0].devices) {
                if (deviceM?.id) {
                    const link = this.renderer.createElement('a');
                    const routeLink = `/device/data/${deviceM?.id}`;
                    this.renderer.setAttribute(link, 'href', routeLink);
                    this.renderer.addClass(link, 'link');
                    this.renderer.appendChild(link, this.renderer.createText(deviceM?.name));
                    this.renderer.listen(link, 'click', (event) => {
                        event.preventDefault();
                        this.router.navigateByUrl(routeLink).then();
                    });
                    this.renderer.appendChild(devicesListContainer, link);
                }
            }
        }

        const infoWindowContent = this.renderer.createElement('div');
        infoWindowContent.innerHTML = `
            <div class="info-container">
                <div class="fa fa-circle ${location.status === 'online' ? 'online' : 'offline'}"></div>
                <div class="name">${location.infos[0].name}</div>      
            </div> <div>City: <span class="city">${location.infos[0].city}</span></div>
                Systems
            `;
        this.renderer.appendChild(infoWindowContent, devicesListContainer);
        const infoWindow: google.maps.InfoWindow = new google.maps.InfoWindow({
            content: infoWindowContent,
        });

        marker.addListener('click', () => {
            infoWindow.setZIndex(this.zIndexCounter++);
            infoWindow.open(this.map.googleMap, marker);
        });

        return marker;
    }

    getDevices() {
        this.api.get('/map/devices').toPromise()
            .then((response: {
                devices: DeviceHomePage[],
                n1_devices: N1DeviceHomePage[],
                markers: any,
                n1_markers: any
            }) => {
                this.dataLoaded = true;

                this.devices = response.devices;
                this.n1Devices = response.n1_devices;

                if (response.markers.length > 0) {
                    this.mapCenter.lat = response.markers[0].latitude;
                    this.mapCenter.lng = response.markers[0].longitude;
                }

                this.markers = this.prepareMarkers(response.markers);

                this.fitMarkersBounds();

                const tmpDevicesInCities = {};

                for (let idx = 0; idx < this.devices.length; idx++) {
                    let city = this.devices[idx].city;
                    if (city === '') {
                        city = 'N/A';
                    }
                    if (tmpDevicesInCities[city] === undefined) {
                        tmpDevicesInCities[city] = [];
                    }
                    tmpDevicesInCities[city].push({
                        ...this.devices[idx],
                        visible: true,
                        type: this.deviceGenerationMulti
                    });
                }
                this.n1Devices.forEach((n1Device) => {
                    const city = n1Device.city.length ? n1Device.city : 'N/A';
                    if (tmpDevicesInCities[city] === undefined) {
                        tmpDevicesInCities[city] = [];
                    }
                    tmpDevicesInCities[city].push({...n1Device, visible: true, type: this.deviceGenerationN1});
                });
                const tmpCities = Object.keys(tmpDevicesInCities).sort();

                const tmp = {};
                for (let idx = 0; idx < tmpCities.length; idx++) {
                    tmp[tmpCities[idx]] = tmpDevicesInCities[tmpCities[idx]].sort(
                        (a: DeviceHomePage | N1DeviceHomePage, b: DeviceHomePage | N1DeviceHomePage) => (a.name > b.name) ? 1 : -1
                    );
                }
                this.devicesInCities = tmp;
                this.cities = tmpCities.slice();
                this.hiddenCities = {};
                setTimeout(() => {
                    // console.time('m');
                    this.activateCities();
                }, 0);

                setTimeout(() => {
                    this.startObserving().then();
                }, 0);

                this.addMarkerCluster();
            });
    }

    addMarkerCluster() {
        this.markerCluster.clearMarkers();
        this.markersInMarkerClusterFormat = Object.values(this.markers)
            .filter((marker: any) => !marker.hidden)
            .map((location: any) => this.convertMarkerToMarkerClustererFormat(location));

        for(let marker of this.markersInMarkerClusterFormat) {
            this.markerCluster.addMarker(marker);
        }
    }

    activateCities() {
        this.activateCity(this.cities.length, 0, 0);
    }

    /**
     * This method enables the display data gradually to let the UI draw smother
     * @param count
     * @param index
     * @param alreadyDisplayedRows
     */
    activateCity(count, index, alreadyDisplayedRows) {
        if (index >= count) {
            setTimeout(() => {
                this.deviceDrawingComplete = true;
                // console.timeEnd('m');
            }, 0);
            return;
        }

        // const city = this.cities[index];
        // this.activeCities[city] = true;
        //
        // // while the displayed data doesn't fill one screen, add all items in a single event loop cycle.
        // // as soon as we are out of screen, draw one "city" at a time using different event loop cycles to
        // // prevent the UI from blocking
        // alreadyDisplayedRows += (1 + this.devicesInCities[city].length);
        // if (alreadyDisplayedRows * 50 < window.innerHeight) {
        // 	this.activateCity(count, index + 1, alreadyDisplayedRows);
        // } else {
        // 	setTimeout(() => {
        // 		this.activateCity(count, index + 1, alreadyDisplayedRows);
        // 	}, 0);
        // }
        let rCount = 0;
        while (rCount < 40) {
            if (index >= count) {
                break;
            }

            const city = this.cities[index];
            this.activeCities[city] = true;

            index++;
            rCount += (1 + this.devicesInCities[city].length);
        }

        setTimeout(() => {
            this.activateCity(count, index, alreadyDisplayedRows);
        }, 0);
    }

    async sleep(ms: number) {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, ms);
        });
    }

    async getMeasurementsForDevice(deviceId: string, deviceGeneration: number, includeOutstandingAlarms: boolean) {
        this.observers[deviceId].observer.unobserve(this.observers[deviceId].element);

        const endpoint = deviceGeneration === this.deviceGenerationN1 ? 'n1_measurements' : 'measurements';
        const url = `/map/${endpoint}/${deviceId}/${includeOutstandingAlarms ? 1 : 0}`;

        this.devicesMeasurements[deviceId] = await this.api.get(url).toPromise();
    }

    onIntersection($event: IntersectionObserverEntry[], deviceId: string) {
        this.intersectionEvents[deviceId] = {
            target: $event[0].target,
            isIntersecting: $event[0].isIntersecting
        };

        if (this.intersectionEventsTimeouts[deviceId]) {
            clearTimeout(this.intersectionEventsTimeouts[deviceId]);
            delete this.intersectionEventsTimeouts[deviceId];
        }
        if (!$event[0].isIntersecting) {
            return;
        }
        const _deviceId = deviceId;
        this.intersectionEventsTimeouts[deviceId] = setTimeout(() => {
            if (!this.intersectionEvents[_deviceId].isIntersecting) {
                return;
            }

            const target = this.intersectionEvents[_deviceId].target;
            // tslint:disable-next-line:no-shadowed-variable
            const deviceId = target.getAttribute('deviceId');
            const deviceGeneration = parseInt(target.getAttribute('deviceGeneration'), 10);
            const deviceStatus = target.getAttribute('deviceStatus');

            let includeOutstandingAlarms = false;
            switch (deviceStatus) {
                case 'offline':
                case 'offline_gateway_online':
                case 'online_old_data':
                    break;
                default:
                    includeOutstandingAlarms = true;
            }

            setTimeout(async () => {
                await this.getMeasurementsForDevice(deviceId, deviceGeneration, includeOutstandingAlarms);
            }, 0);
        }, 50);
    }

    async waitForDevicesContainer() {
        while (!this.devicesContainer) {
            await this.sleep(10);
        }

        // return this.devicesContainer.elRef.nativeElement;
        return this.devicesContainer.nativeElement;
    }

    async startObserving() {
        const mapContainer = await this.waitForDevicesContainer();
        const devices = this.elementRef.nativeElement.querySelectorAll('kntz-device[observed="false"]');

        const options = {
            root: mapContainer,
            rootMargin: '0px 0px 100% 0px',
            threshold: 0.25
        };

        for (let idx = 0; idx < devices.length; idx++) {
            const deviceId = devices[idx].getAttribute('deviceId');
            this.observers[deviceId] = {
                observer: new IntersectionObserver(($event, obs) => {
                    this.onIntersection($event, deviceId);
                }, options),
                element: devices[idx]
            };
            this.observers[deviceId].observer.observe(devices[idx]);
            devices[idx].setAttribute('observed', 'true');
        }

        if (!this.deviceDrawingComplete || devices.length) {
            setTimeout(() => {
                this.startObserving().then();
            }, 500);
        }
    }

    filterGateways(gateways: number[] | null) {
        if (gateways === null) {
            this.showAllSystems();
            return;
        }

        const gatewaysHash = {};
        this.filteredSystemsList = true;
        for (let idx = 0; idx < gateways.length; idx++) {
            gatewaysHash[gateways[idx]] = true;
        }

        this.hiddenCities = [];
        for (const city of Object.keys(this.devicesInCities)) {
            let visibleSystems = 0;
            for (let idx = 0; idx < this.devicesInCities[city].length; idx++) {
                if (gatewaysHash[this.devicesInCities[city][idx].gatewayId] !== undefined) {
                    this.devicesInCities[city][idx].visible = true;
                    visibleSystems++;
                } else {
                    this.devicesInCities[city][idx].visible = false;
                }
            }

            if (visibleSystems === 0) {
                this.hiddenCities[city] = true;
            }
        }

        for (const key of Object.keys(this.markers)) {
            let visibleSystems = 0;
            for (let idx = 0; idx < this.markers[key].items.length; idx++) {
                if (gatewaysHash[this.markers[key].items[idx].gatewayId] !== undefined) {
                    this.markers[key].items[idx].visible = true;
                    visibleSystems++;
                } else {
                    this.markers[key].items[idx].visible = false;
                }
            }

            this.markers[key].hidden = (visibleSystems === 0);
            this.markers[key].infos = this.prepareMarkerInfo(this.markers[key]);
        }

        this.addMarkerCluster();
    }

    /**
     * Makes all the devices visible
     */
    showAllSystems(): boolean {
        this.filteredSystemsList = false;
        this.hiddenCities = [];
        for (const city of Object.keys(this.devicesInCities)) {
            for (let idx = 0; idx < this.devicesInCities[city].length; idx++) {
                this.devicesInCities[city][idx].visible = true;
            }
        }

        for (const key of Object.keys(this.markers)) {
            for (let idx = 0; idx < this.markers[key].items.length; idx++) {
                this.markers[key].items[idx].visible = true;
            }

            this.markers[key].hidden = false;
            this.markers[key].infos = this.prepareMarkerInfo(this.markers[key]);
        }

        this.addMarkerCluster();

        return false; // to prevent UI reload
    }

    fitMarkersBounds() {
        const bounds = this.getBounds(this.markers);
        this.map.googleMap.fitBounds(bounds, 0);
    }

    getBounds(markers: any): google.maps.LatLngBoundsLiteral {
        let minLat = Infinity;
        let maxLat = -Infinity;
        let minLng = Infinity;
        let maxLng = -Infinity;

        for (const key of Object.keys(markers)) {
            minLat = Math.min(minLat, markers[key].position.lat);
            maxLat = Math.max(maxLat, markers[key].position.lat);
            minLng = Math.min(minLng, markers[key].position.lng);
            maxLng = Math.max(maxLng, markers[key].position.lng);
        }

        return {
            north: maxLat,
            south: minLat,
            east: maxLng,
            west: minLng
        };
    }
}
