import {Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import { UsersGrid, UsersTree } from './users.model';
import { ColumnType } from '@models/column.types';
import { Router } from '@angular/router';
import { ROUTES } from '@const/routes';
import { ApiService } from '@svc/api.service';
import { ProcessCellForExportParams, ViewportChangedEvent} from 'ag-grid-community';
import { ButtonsRendererComponent } from '@comp/buttons.renderer/buttons.renderer.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

@Component({
	selector: 'kntz-users-page',
	templateUrl: './users.page.html',
	styleUrls: ['./users.page.scss'],
	encapsulation: ViewEncapsulation.None
})
export class UsersPageComponent implements OnInit, OnDestroy {

	constructor(
		private router: Router,
		private api: ApiService,
		private modalService: BsModalService
	) {
		this.clickEventsubscription = this.api.getClickEvent().subscribe(() => {
			this.afterRemoveUser();
		});
	}
	grid: UsersTree = UsersGrid;

	public apiResponse;
	public tree = [];
	private gridApi;
	public searchValue;
	clickEventsubscription: Subscription;
	tmp = [];

	public columnDefs = [
		{
			field: 'email',
			headerName: 'Email',
			minWidth: 100,
		},
		{
			field: 'role',
			headerName: 'Role',
			minWidth: 100,
		},
		{
			type: 'customActions',
			cellRenderer: 'buttonsRenderer',
			width: 150,
			minWidth: 100,
			headerName: 'Actions',
			resizable: false,
			filter: false,
			cellRendererParams: {
				buttons: [
					{
						icon: 'fa-edit',
						tooltip: 'Edit',
						type: 'edit',
						isIcon: true
					},
					{
						icon: 'fa-trash',
						tooltip: 'Delete',
						type: 'delete',
						dataFieldCondition: 'deleteAllowed',
						isIcon: true
					}
				]
			}
		}
	];
	public defaultColDef = {
		flex: 1,
		cellStyle: { 'background-color': '#050d18' },
		sortable: true,
	};
	public autoGroupColumnDef = {
		headerName: 'Name',
		minWidth: 120,
		cellRendererParams: {
			suppressCount: true
		}
	};
	public getDataPath = this.createDataPathFunction();
	public frameworkComponents = {
		buttonsRenderer: ButtonsRendererComponent
	};
	modalItemId: any;
	modalRef: BsModalRef;

	// security improvement to prevent CSV injections
	public exportParams = {
		processCellCallback(params: ProcessCellForExportParams): string {
			return params.value === undefined || params.value === null ? '' : (params.value + '').replace(/^([=+\-@\t\r])/, '\t$1');
		}
	};

	public visitedIds = {};

	onGridReady(params) {
		this.gridApi = params.api;

	}

	quickSearch() {
		this.gridApi.setQuickFilter(this.searchValue);
	}

	onGridButtonClick(columnType: ColumnType) {
		this.router.navigate([ROUTES.editUsers]);
	}

	ngOnInit() {
		this.loadTree();
	}

	ngOnDestroy() {
		this.clickEventsubscription.unsubscribe();
	}

	isHierarchyInTree(hierarchy, tree) {
		for (let idxTree = 0; idxTree < tree.length; idxTree++) {
			const treeItem = tree[idxTree];

			if (hierarchy.length != treeItem.hierarchy.length) {
				continue;
			}

			if (JSON.stringify(hierarchy) === JSON.stringify(treeItem.hierarchy)) {
				return true;
			}
		}

		return false;
	}

	loadTree() {
		this.visitedIds = {};
		this.api.get('/users/list').toPromise()
			.then((response) => {
				this.tmp = this.parseTree(response);

				const tree = [];

				for (let idx = 0; idx < this.tmp.length; idx++) {
					const item = this.tmp[idx];

					// find if there is another item in the tree having the same hierarchy
					while (this.isHierarchyInTree(item.hierarchy, tree)) {
						item.hierarchy[item.hierarchy.length - 1] += ' ';
					}

					tree.push(item);
				}
				this.tree = tree;
			});
	}

	createDataPathFunction() {
		return (data) => {
			return data.hierarchy;
		};
	}

	parseTree(tree) {
		if (tree === null) {
			return [];
		}

		const ret = [];
		for (let idx = 0; idx < tree.length; idx++) {
			const item = tree[idx];

			if (this.visitedIds[item.id] !== undefined) {
				continue;
			}

			this.visitedIds[item.id] = true;

			ret.push({
				hierarchy: [item.name],
				id: item.id,
				email: item.email,
				name: item.name,
				role: item.role,
				deleteAllowed: item.deleteAllowed
			});

			const children = this.parseTree(item.children);

			for (let idxChildren = 0; idxChildren < children.length; idxChildren++) {
				const child = children[idxChildren];

				child.hierarchy.splice(0, 0, item.name);
				ret.push({
					hierarchy: child.hierarchy,
					id: child.id,
					email: child.email,
					name: child.name,
					role: child.role,
					deleteAllowed: child.deleteAllowed
				});
			}
		}

		return ret;
	}

	onViewportChanged(params: ViewportChangedEvent) {
		params.api.sizeColumnsToFit();
	}

	onButtonClicked(params, type) {
		const userId = params.data.id;
		// const userName = params.data.name;

		switch (type) {
			case 'delete':
				this.api.delete('/users', { userId: userId, confirmation: 1 }).toPromise()
					.then(() => {
						this.router.routeReuseStrategy.shouldReuseRoute = () => false;
						this.router.navigate(['/users']).then();
					})
					.catch((err) => {
						alert('Error encountered while trying to delete the user');
					});
				break;
			case 'edit':
				this.router.navigate(['/users/edit', userId]).then();
				break;
		}
	}

	private afterRemoveUser() {
		this.tmp = [];
		this.loadTree();
	}
}
