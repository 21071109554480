/**
 * Measurements preferred order
 */
export const preferredDisplayOrder = [
	'ph',
	'dis',
	'mea',
	'tcl',
	'sulfite',
];

/**
 * Converts a tile name to a color
 * @param tileName ph-0, tcl-0, etc
 */
export function getMeasurementColor(tileName: string): string {
	let color = '#fff';
	switch (tileName.toLowerCase().replace(/[^a-z0-9]/g, '')) {
		case 'dis':
			color = '#03bfc0';
			break;
		case 'mea':
			color = '#03bfc0';
			break;
		case 'ph':
			color = '#a2cc38';
			break;
		// case 'temp':
		// 	color = '#ee4036';
		// 	break;
		// case 'cl2':
		// 	color = '#03bfc0';
		// 	break;
		// case 'cl22':
		// 	color = '#03bfc0';
		// 	break;
		// case 'clo2':
		// 	color = '#03bfc0';
		// 	break;
		// case 'o3':
		// 	color = '#03bfc0';
		// 	break;
		// case 'h2o2':
		case 'sulfite':
		 	color = '#03bfc0';
		 	break;
		// case 'redox':
		// 	color = '#3dbb7e';
		// 	break;
		case 'tcl':
			color = '#3399fd';
			break;
		// case 'ocl':
		// 	color = '#3399fd';
		// 	break;
		// case 'ec':
		// 	color = '#3399fd';
		// 	break;
		// case 'ecil':
		// 	color = '#3399fd';
		// 	break;
		// case 'gesamtchlor':
		// 	color = '#3399fd';
		// 	break;
	}

	return color;
}
