import {
	Component,
	OnInit,
	OnDestroy,
} from '@angular/core';

import { ApiService } from '@svc/api.service';
import {ROUTES} from '@const/routes';
import {AuthService} from '@svc/auth.service';
import {Router} from '@angular/router';
import Chart, {ChartPoint} from 'chart.js';
import moment from 'moment';
import {AngularPageVisibilityStateEnum, OnPageVisibilityChange} from 'angular-page-visibility';

interface GraphInterval {
	label: string;
	interval: number;
	refresh: number;
}

interface Top10Alarm {
	device_id: number;
	cnt: number;
	name: string;
	serial: string;
	city: string;
}

interface Top10AlarmDetails {
	event: number;
	cnt: number;
	text: string;
}

@Component({
	selector: 'kntz-stats-page',
	templateUrl: './stats.page.html',
	styleUrls: ['./stats.page.scss'],
})
export class StatsPageComponent implements OnInit, OnDestroy {
	private pageVisible = true;

	public graphIntervals: GraphInterval[] = [
		{label: '2 Years', interval: 63072000, refresh: 14400},
		{label: '1 Year', interval: 31536000, refresh: 7200},
		{label: '180 Days', interval: 15552000, refresh: 7200},
		{label: '90 Days', interval: 7776000, refresh: 3600},
		{label: '30 Days', interval: 2592000, refresh: 3600},
		{label: '1 Week', interval: 604800, refresh: 600},
		{label: '24 Hours', interval: 86400, refresh: 60},
	];
	public gatewaysGraphSelectedInterval = 86400;
	public systemsGraphSelectedInterval = 86400;
	public top10GraphSelectedInterval = 86400;

	public gatewaysGraphLoaded = false;
	public systemsGraphLoaded = false;
	public top10AlarmsLoaded = false;
	public top10AlarmDetailLoading: {[key: string]: boolean} = {};

	public chartGatewaysOptions: Chart.ChartOptions = {
		responsive: true,
		maintainAspectRatio: false,
		scales: {
			xAxes: [{
				id: 'x-axis',
				type: 'time',
				time: {
					unit: 'minute',
					displayFormats: {
						minute: 'MM/DD/YYYY HH:mm'
					},
					stepSize: 10
				},
				ticks: {
					source: 'data',
					maxRotation: 0,
					autoSkip: true,
					autoSkipPadding: 75,
					fontColor: '#ddd',
					fontSize: 12,
					padding: 5
				},
				bounds: 'ticks',
				gridLines: {
					color: '#555',
					drawBorder: true,
					tickMarkLength: 0
				},
			}],
			yAxes: [
				{
					id: 'y-axis',
					type: 'linear',
					gridLines: {
						color: '#777',
						drawOnChartArea: true,
						tickMarkLength: 5
					},
					ticks: {
						fontColor: '#fff',
						fontSize: 11,
						padding: 3,
						// maxTicksLimit: 2,
						beginAtZero: true,
						min: 0,
					}
				}
			],
		},
		animation: {
			duration: 0
		},
		legend: {
			display: true,
			labels: {
				fontColor: '#d7d7d7'
			},
			align: 'start',
			position: 'bottom',
		},
	};
	public chartGatewaysDataSets: Chart.ChartDataSets[] = [
		{
			type: 'line',
			data: [],
			label: 'Online',
			pointRadius: 0,
			fill: false,
			borderColor: 'rgb(69,191,85)',
		},
		{
			type: 'line',
			data: [],
			label: 'Offline',
			pointRadius: 0,
			fill: false,
			borderColor: 'rgb(240,78,40)',
		}
	];

	public chartSystemsOptions: Chart.ChartOptions = {
		responsive: true,
		maintainAspectRatio: false,
		scales: {
			xAxes: [{
				id: 'x-axis',
				type: 'time',
				time: {
					unit: 'minute',
					displayFormats: {
						minute: 'MM/DD/YYYY HH:mm'
					},
					stepSize: 10
				},
				ticks: {
					source: 'data',
					maxRotation: 0,
					autoSkip: true,
					autoSkipPadding: 75,
					fontColor: '#ddd',
					fontSize: 12,
					padding: 5
				},
				bounds: 'ticks',
				gridLines: {
					color: '#555',
					drawBorder: true,
					tickMarkLength: 0
				},
			}],
			yAxes: [
				{
					id: 'y-axis',
					type: 'linear',
					gridLines: {
						color: '#777',
						drawOnChartArea: true,
						tickMarkLength: 5
					},
					ticks: {
						fontColor: '#fff',
						fontSize: 11,
						padding: 3,
						beginAtZero: true,
						min: 0,
					}
				}
			],
		},
		animation: {
			duration: 0
		},
		legend: {
			display: true,
			labels: {
				fontColor: '#d7d7d7'
			},
			align: 'start',
			position: 'bottom',
		},
	};
	public chartSystemsDataSets: Chart.ChartDataSets[] = [
		{
			type: 'line',
			data: [],
			label: 'Online',
			pointRadius: 0,
			fill: false,
			borderColor: 'rgb(69,191,85)',
		},
		{
			type: 'line',
			data: [],
			label: 'Historic upload',
			pointRadius: 0,
			fill: false,
			borderColor: 'rgb(79,128,189)',
		},
		{
			type: 'line',
			data: [],
			label: 'Offline',
			pointRadius: 0,
			fill: false,
			borderColor: 'rgb(240,78,40)',
		},
		{
			type: 'line',
			data: [],
			label: 'Offline (but gw. online)',
			pointRadius: 0,
			fill: false,
			borderColor: 'rgb(117,8,0)',
		}
	];

	public top10Alarms: Top10Alarm[] = [];
	public top10AlarmDetailsShow: {[key: string]: boolean} = {};
	public top10AlarmsStartTs: number;
	public top10AlarmsEndTs: number;
	public top10AlarmDetails: {[key: number]: Top10AlarmDetails[] } = {};

	private timers = {};

	constructor(
		private api: ApiService,
		private authService: AuthService,
		private router: Router
	) {
	}

	ngOnInit() {
		if (!this.authService.haveStatsPermission()) {
			this.router.navigate([ROUTES.default]).then();
			return;
		}

		this.gatewaySelectInterval(this.graphIntervals[this.graphIntervals.length - 1]).then();
		this.systemSelectInterval(this.graphIntervals[this.graphIntervals.length - 1]).then();
		this.top10SelectInterval(this.graphIntervals[this.graphIntervals.length - 1]).then();
	}

	ngOnDestroy() {
		// cleanup timers
		Object.keys(this.timers).forEach((timerKey) => {
			if (this.timers[timerKey]) {
				clearTimeout(this.timers[timerKey]);
				this.timers[timerKey] = null;
			}
		});
	}

	/**
	 * Converts the values to a ChartPoint
	 * @param ts
	 * @param value
	 */
	getChartPoint(ts: number, value: number): ChartPoint {
		return {
			x: ts,
			y: value
		};
	}

	/**
	 * Helper function to silence the compiler
	 * @param cp
	 */
	getChartPointTs(cp: ChartPoint): number {
		return cp.x as number;
	}

	/**
	 * Pads the dataset with nulls in case it's not up to date (because of a backend error)
	 * @param datasetConfig
	 */
	adjustDataSetForMissingCurrentData(datasetConfig: Chart.ChartDataSets) {
		const dataset = datasetConfig.data as ChartPoint[];

		if (dataset.length < 2) {
			// we need at least two data points to see the point interval
			return;
		}

		const now = moment.now();
		const nowTs = now.valueOf();

		const lastPoint = dataset[dataset.length - 1] as ChartPoint;
		const lastPointTs = this.getChartPointTs(lastPoint);

		const timeDelta = lastPointTs - this.getChartPointTs(dataset[dataset.length - 2] as ChartPoint);

		for (let ts = lastPointTs + timeDelta; ts < nowTs; ts += timeDelta) {
			dataset.push(this.getChartPoint(ts, null));
		}
	}

	async gatewaySelectInterval(interval: GraphInterval) {
		await this.waitForPageVisible();

		this.gatewaysGraphSelectedInterval = interval.interval;

		this.gatewaysGraphLoaded = false;
		const response = await this.api.post('/stats/getGatewayStatuses', {interval: interval.interval}).toPromise();

		this.chartGatewaysDataSets[0].data = [] as ChartPoint[];
		this.chartGatewaysDataSets[1].data = [] as ChartPoint[];
		const online = this.chartGatewaysDataSets[0].data;
		const offline = this.chartGatewaysDataSets[1].data;

		let max = 2;

		for (const row of response.data) {
			online.push(this.getChartPoint(row.ts * 1000, row.online));
			offline.push(this.getChartPoint(row.ts * 1000, row.offline));

			max = Math.max(max, row.online, row.offline);
		}

		this.chartGatewaysOptions.scales.yAxes[0].ticks.max = Math.ceil(max / 50) * 50;
		this.chartGatewaysOptions.scales.xAxes[0].time.displayFormats.minute = interval.interval === 86400 ? 'HH:mm' : 'MM/DD HH:mm';

		for (let idx = 0; idx < this.chartGatewaysDataSets.length; idx++) {
			this.adjustDataSetForMissingCurrentData(this.chartGatewaysDataSets[idx]);
		}

		this.gatewaysGraphLoaded = true;

		this.setupGatewaysRefreshTimer();
	}

	async systemSelectInterval(interval: GraphInterval) {
		await this.waitForPageVisible();

		this.systemsGraphSelectedInterval = interval.interval;

		this.systemsGraphLoaded = false;
		const response = await this.api.post('/stats/getSystemsByStatus', {interval: interval.interval}).toPromise();

		this.chartSystemsDataSets[0].data = [] as ChartPoint[];
		this.chartSystemsDataSets[1].data = [] as ChartPoint[];
		this.chartSystemsDataSets[2].data = [] as ChartPoint[];
		this.chartSystemsDataSets[3].data = [] as ChartPoint[];
		const online = this.chartSystemsDataSets[0].data;
		const historicalUpload = this.chartSystemsDataSets[1].data;
		const offline = this.chartSystemsDataSets[2].data;
		const offlineGatewayOnline = this.chartSystemsDataSets[3].data;

		let max = 2;

		for (const row of response.data) {
			online.push(this.getChartPoint(row.ts * 1000, row.online));
			historicalUpload.push(this.getChartPoint(row.ts * 1000, row.online_old_data));
			offline.push(this.getChartPoint(row.ts * 1000, row.offline));
			offlineGatewayOnline.push(this.getChartPoint(row.ts * 1000, row.offline_gateway_online));

			max = Math.max(max, row.online, row.online_old_data, row.offline, row.offline_gateway_online);
		}

		this.chartSystemsOptions.scales.yAxes[0].ticks.max = Math.ceil(max / 50) * 50;
		this.chartSystemsOptions.scales.xAxes[0].time.displayFormats.minute = interval.interval === 86400 ? 'HH:mm' : 'MM/DD HH:mm';

		for (let idx = 0; idx < this.chartSystemsDataSets.length; idx++) {
			this.adjustDataSetForMissingCurrentData(this.chartSystemsDataSets[idx]);
		}

		this.systemsGraphLoaded = true;

		this.setupSystemsRefreshTimer();
	}

	async top10SelectInterval(interval: GraphInterval) {
		await this.waitForPageVisible();

		this.top10GraphSelectedInterval = interval.interval;

		this.top10AlarmDetails = {};
		this.top10AlarmDetailLoading = {};
		this.top10AlarmDetailsShow = {};

		this.top10AlarmsLoaded = false;
		const response = await this.api.post('/stats/top10SystemsByAlarms', {interval: interval.interval}).toPromise();

		this.top10Alarms = response.data;
		this.top10AlarmsStartTs = response.startTs;
		this.top10AlarmsEndTs = response.endTs;
		this.top10AlarmsLoaded = true;

		this.setupTop10AlarmsRefreshTimer();
	}

	async toggleTop10AlarmDetails(position: number) {
		this.top10AlarmDetailsShow[position] = !this.top10AlarmDetailsShow[position];

		if (this.top10AlarmDetailsShow[position]) {
			await this.top10GetDetails(position);
		}

		// resetting the timer whenever somebody clicks the details to allow them to read the details
		this.setupTop10AlarmsRefreshTimer();
	}

	async top10GetDetails(position: number) {
		const item = this.top10Alarms[position];
		const deviceId = item.device_id;

		if (this.top10AlarmDetails[position] === undefined) {
			this.top10AlarmDetailLoading[position] = true;
			const response = await this.api.post('/stats/top10SystemsByAlarmsDetails', {
				systemId: deviceId,
				startTs: this.top10AlarmsStartTs,
				endTs: this.top10AlarmsEndTs,
			}).toPromise();

			this.top10AlarmDetails[position] = response.data;
			this.top10AlarmDetailLoading[position] = false;
		}
	}

	_setupRefresh(timerKey: string, selectedIntervalTime: number, refreshFunction: Function) {
		if (this.timers[timerKey]) {
			clearTimeout(this.timers[timerKey]);
		}

		const selectedInterval = this.graphIntervals[
			this.graphIntervals.findIndex((item) => item.interval === selectedIntervalTime)
		];
		const refreshTime = selectedInterval.refresh;
		this.timers[timerKey] = setTimeout(refreshFunction.bind(this, selectedInterval), refreshTime * 1000);
	}

	setupGatewaysRefreshTimer() {
		this._setupRefresh('gateways-refresh-timer', this.gatewaysGraphSelectedInterval, this.gatewaySelectInterval);
	}

	setupSystemsRefreshTimer() {
		this._setupRefresh('systems-refresh-timer', this.systemsGraphSelectedInterval, this.systemSelectInterval);
	}

	setupTop10AlarmsRefreshTimer() {
		this._setupRefresh('top10alarms-refresh-timer', this.top10GraphSelectedInterval, this.top10SelectInterval);
	}

	@OnPageVisibilityChange()
	handlePageVisibilityChange(visibilityState: AngularPageVisibilityStateEnum): void {
		this.pageVisible = AngularPageVisibilityStateEnum[visibilityState]
			=== AngularPageVisibilityStateEnum[AngularPageVisibilityStateEnum.VISIBLE];
	}

	async sleep(ms: number) {
		return new Promise((resolve) => {
			setTimeout(() => {
				resolve();
			}, ms);
		});
	}

	async waitForPageVisible() {
		while (!this.pageVisible) {
			await this.sleep(500);
		}
	}
}
