import { Component, ViewEncapsulation, OnDestroy, TemplateRef } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

import { ColumnType } from '@models/column.types';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ApiService } from '@svc/api.service';

interface CustomButton {
	icon: string;
	type: string;
	tooltip?: string;
	dataFieldCondition?: string;
}

@Component({
	selector: 'kntz-buttons-renderer',
	templateUrl: './buttons.renderer.component.html',
	styleUrls: ['./buttons.renderer.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class ButtonsRendererComponent implements ICellRendererAngularComp, OnDestroy {
	modalRef: BsModalRef;
	public customActions;
	public customButtons;

	public buttonType: string;
	public params: ICellRendererParams;
	public type = ColumnType;

	constructor(private modalService: BsModalService,
				private api: ApiService
	) {
	}

	agInit(params: ICellRendererParams): void {
		this.params = params;
		this.buttonType = (params.colDef.type || params.colDef.field) as string;
		this.customActions = (params.colDef.type === 'customActions');
		if (this.customActions) {
			this.customButtons = [];
			for (let idx = 0; idx < params.colDef.cellRendererParams.buttons.length; idx++) {
				const button = params.colDef.cellRendererParams.buttons[idx];
				const shouldBeAdded = (button.dataFieldCondition === undefined) ||
					(params.data[button.dataFieldCondition] === undefined || params.data[button.dataFieldCondition]);
				if (shouldBeAdded) {
					this.customButtons.push(button);
				}
			}
		}
	}

	refresh(params: ICellRendererParams): boolean {
		return true;
	}

	onBtnClick(buttonType: string): void {
		this.emitButtonClick(buttonType);

		if (this.params.context.onButtonClicked) {
			this.params.context.onButtonClicked(this.params, buttonType);
		}
	}

	customButtonClick(button) {
		if (this.params.context.onButtonClicked) {
			this.params.context.onButtonClicked(this.params, button.type);
		}
	}

	ngOnDestroy(): void {
	}

	private emitButtonClick(buttonType: string): void {
		if (this.params.context.gridButtonClick) {
			this.params.context.gridButtonClick.emit(buttonType);
		}
	}

	openModal(template: TemplateRef<any>) {
		this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
	}

	cancel(): void {
		this.modalRef.hide();
	}

	confirmDelete(params) {
		this.modalRef.hide();
		if (params.context.onButtonClicked) {
			params.context.onButtonClicked(this.params, 'delete');
		}
	}

	confirmWiFi(gatewayId: number) {
		this.api.post('/gateways/wifion', { gatewayId: gatewayId, confirmation: 1 }).toPromise().then(response => {
			if (response === true) {
				alert('Wifi start requested successfully.');

			} else {
				alert('Wifi start requested recently. Please try again later.');
			}
		});
		this.modalRef.hide();
	}
	showCancelSaveButtons(index) {
		return this.api.cancelSaveButtonValues[index];
	}

	clickButton(button: any, deleteTemplate: TemplateRef<any>, wifiTemplate: TemplateRef<any>) {
		switch (button.type) {
			case 'delete':
				this.openModal(deleteTemplate);
				break;
			case 'wifi':
				this.openModal(wifiTemplate);
				break;
			default:
				this.customButtonClick(button);
		}

	}
}
