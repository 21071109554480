import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ApiService } from '@svc/api.service';
import { AddGatewayModel } from '@pages/add.gateway/edit.gateway.model';
import moment from 'moment';
import { KuntzeMapStyle } from '@pages/home/map.model';
import { Router } from '@angular/router';
import {NominatimService} from '@svc/nominatim.service';

@Component({
	selector: 'kntz-edit-gateway-page',
	templateUrl: './edit.gateway.page.html',
	styleUrls: ['./edit.gateway.page.scss'],
	encapsulation: ViewEncapsulation.None
})
export class EditGatewayPageComponent implements OnInit {
	public mapOptions: google.maps.MapOptions = {
		styles: KuntzeMapStyle,
		fullscreenControl: false,
		mapTypeControl: false,
		panControl: false,
	};
	public mapCenter: google.maps.LatLng;
	public mapZoomLevel = 18;

	public markerPosition: google.maps.LatLng;
	public markerOptions: google.maps.MarkerOptions = {
		draggable: true,
		icon: 'assets/images/marker_' + 'red' + '.png',
	};

	public dataLoaded = false;
	public saveInProgress = false;

	constructor(private api: ApiService,
				private router: Router,
				private nominatimService: NominatimService,
	) {
	}

	errorCity: boolean;
	errorName: boolean;
	gatewayId: number;
	uploadIntervalToSend: number;
	public installationDateToSend = null;
	subscriptionDateToSend = null;
	userCanChangeSubscriptionDate: boolean;
	userHasEditUploadTimingPermission: boolean;
	// public mapStyle = KuntzeMapStyle;
	toggleSidebar: boolean;
	public showAddressError: boolean;
	public searchLocationField: any;
	private map;

	companies = [];
	companiesNames = [];
	companyName = '';
	uploadIntervals = ['30 seconds',
		'1 minute',
		'2 minutes',
		'3 minutes',
		'5 minutes',
		'7 minutes',
		'10 minutes',
		'15 minutes'];
	availableGateway = false;
	countriesObject: any;
	countries: string[];
	timezones: any;
	timeZoneInitials: string;
	timezoneToSend = 'UTC';
	country = '------------';
	timeZoneKey: string[];
	timeZoneObjectToDisplay = {};
	companyId: number;
	gatewayObj: AddGatewayModel = {
		serial: '',
		name: '',
		city: '',
		syncTime: 0,
		uploadInterval: 0,
		hidden: 0,
		subscriptionDate: '',
		installationDate: '',
		timezone: '',
		latitude: 51.316349,
		longitude: 6.681056
	};
	uploadInterval: string;
	data: string;
	public installationDate: string;
	errorCompany: boolean;
	loadingInProgress = false;

	public isGateway = false;
	public isNeon1 = false;

	getPositionObject(latitude: number, longitude: number): google.maps.LatLng {
		return new google.maps.LatLng(latitude, longitude);
	}

	async verifySerial() {
		if (this.gatewayObj.serial.length !== 16) {
			return;
		}

		this.loadingInProgress = true;

		try {
			const response = await this.api.post('/gateways/verifyUnassignedGateway', {serial: this.gatewayObj.serial}).toPromise();
			this.availableGateway = response.found;
			if (response.found === false) {
				this.loadingInProgress = false;
			} else if (response.found === true) {
				this.gatewayId = response.id;

				let res: any;
				if (response.type === 'n1') {
					res = await this.api.get('/n1/gateways/details/' + this.gatewayId).toPromise();
					this.gatewayObj = res.n1;
					this.isGateway = false;
					this.isNeon1 = true;
				} else {
					res = await this.api.get('/gateways/details/' + this.gatewayId).toPromise();
					this.gatewayObj = res.gateway;
					this.isGateway = true;
					this.isNeon1 = false;

					this.countriesObject = res.countries;
					this.countries = Object.keys(res.countries);
					this.timezones = res.timezones;
				}

				this.uploadInterval = this.prepareTime(this.gatewayObj.uploadInterval);
				this.data = this.prepareDate(this.gatewayObj.subscriptionDate);
				this.installationDate = this.prepareDate(this.gatewayObj.installationDate);
				this.userCanChangeSubscriptionDate = res.userCanChangeSubscriptionDate;
				this.userHasEditUploadTimingPermission = res.userHasEditUploadTimingPermission;
				this.loadingInProgress = false;

				const defaultLatitude = 51.316349;
				const defaultLongitude = 6.681056;

				this.mapCenter = this.getPositionObject(defaultLatitude, defaultLongitude);
				this.markerPosition = this.getPositionObject(defaultLatitude, defaultLongitude);

				this.subscriptionDateToSend = (this.gatewayObj.subscriptionDate === '' || this.gatewayObj.subscriptionDate === null) ?
					'' : moment(this.gatewayObj.subscriptionDate).format('YYYY-MM-DD');
				this.installationDateToSend = (this.gatewayObj.installationDate === '' || this.gatewayObj.installationDate === null) ?
					'' : moment(this.gatewayObj.installationDate).format('YYYY-MM-DD');

				// by default, when adding a gateway, Sync Time should be on
				this.gatewayObj.syncTime = 1;
				this.dataLoaded = true;
			}
		} catch (_) {
			alert('Error loading the gateway information');
			this.loadingInProgress = false;
		}
	}

	getCompanies() {
		this.api.get('/gateways/companies').toPromise().then(response => {
			this.companies = response;
			Object.values(this.companies).forEach(val => {
				this.companiesNames.push(val.name);
			});
			this.loadingInProgress = false;
		});
	}

	ngOnInit(): void {
		this.loadingInProgress = true;
		this.getCompanies();
	}

	addGateway() {
		if (this.uploadInterval === '30 seconds') {
			this.uploadIntervalToSend = 30;
		} else if (this.uploadInterval === '10 minutes' || this.uploadInterval === '15 minutes') {
			this.uploadIntervalToSend = parseInt(this.uploadInterval.slice(0, 2), 10) * 60;
		} else {
			this.uploadIntervalToSend = parseInt(this.uploadInterval.slice(0, 1), 10) * 60;
		}
		const selectedCompany = this.companies.find(c => c.name === this.companyName);
		if (selectedCompany !== undefined) {
			this.companyId = selectedCompany.id;
		} else {
			alert('Please pick a company');
		}

		this.verifyCity();
		this.verifyCompany();

		const position = this.markerPosition ?? this.mapCenter;

		let promise: Promise<any>;
		if (this.isGateway) {
			const data = {
				'serial': this.gatewayObj.serial,
				'name': this.gatewayObj.name,
				'city': this.gatewayObj.city,
				'latitude': position.lat(),
				'longitude': position.lng(),
				'timezone': this.timezoneToSend,
				'syncTime': this.gatewayObj.syncTime,
				'uploadInterval': this.uploadIntervalToSend,
				'hidden': this.gatewayObj.hidden,
				'subscriptionDate': this.subscriptionDateToSend,
				'installationDate': this.installationDateToSend,
				'assignToCompanyId': this.companyId
			};

			if (data.city.length < 1 || data.name.length < 3 || this.companyName === '') {
				return;
			}

			promise = this.api.post('/gateways/edit/' + 0, data).toPromise();
		} else if (this.isNeon1) {
			const data = {
				'serial': this.gatewayObj.serial,
				'city': this.gatewayObj.city,
				'latitude': position.lat(),
				'longitude': position.lng(),
				'uploadInterval': this.uploadIntervalToSend,
				'hidden': this.gatewayObj.hidden,
				'subscriptionDate': this.subscriptionDateToSend,
				'installationDate': this.installationDateToSend,
				'assignToCompanyId': this.companyId
			};

			if (data.city.length < 1 || this.companyName === '') {
				return;
			}

			promise = this.api.post('/n1/gateways/edit/00000000-0000-0000-0000-000000000000', data).toPromise();
		}

		if (promise) {
			this.saveInProgress = true;
			promise
				.then(() => {
					this.router.navigateByUrl('/gateways').then();
				})
				.catch(() => {
					alert('Error saving gateway');
				}).finally(() => {
					this.saveInProgress = false;
				});
		}
	}

	verifyDescription() {
		this.errorName = this.gatewayObj.name.length < 3;
	}

	verifyCity() {
		this.errorCity = this.gatewayObj.city.length < 1;
	}

	verifyCompany() {
		this.errorCompany = this.companyName === '';
	}

	dataPickSubscription(event) {
		this.subscriptionDateToSend = '';
		if (event === null || /invalid/i.test(event.toString()) || event.toString().length === 0) {
			return;
		}

		this.subscriptionDateToSend = moment(event).format('YYYY-MM-DD');
	}

	dataPickInstallation(event) {
		this.installationDateToSend = '';
		if (event === null || /invalid/i.test(event.toString()) || event.toString().length === 0) {
			return;
		}

		this.installationDateToSend = moment(event).format('YYYY-MM-DD');
	}

	selectTimezone() {
		if (this.country === '------------') {
			this.timeZoneInitials = 'UU';
		} else {
			for (const i in this.countries) {
				if (this.country === this.countriesObject[this.countries[i]]) {
					this.timeZoneInitials = this.countries[i];
					break;
				}
			}
		}

		if (this.timeZoneInitials !== 'UU') {
			this.timeZoneKey = Object.keys(this.timezones[this.timeZoneInitials][0]);
		}

		this.timeZoneObjectToDisplay = {};
		if (this.timeZoneInitials !== 'UU') {
			for (const v in this.timeZoneKey) {
				if (this.timeZoneObjectToDisplay[this.timeZoneInitials] === undefined) {
					this.timeZoneObjectToDisplay[this.timeZoneInitials] = [];
				}
				if (this.timeZoneInitials !== 'UU') {
					this.timeZoneObjectToDisplay[this.timeZoneInitials].push({
						name: this.timeZoneKey[v].split('/')[1],
						hours: this.timezones[this.timeZoneInitials][0][this.timeZoneKey[v]].offsetHours,
						minutes: this.timezones[this.timeZoneInitials][0][this.timeZoneKey[v]].offsetMinutes,
						dst: this.timezones[this.timeZoneInitials][0][this.timeZoneKey[v]].isDst,
						timeDelay: this.timezones[this.timeZoneInitials][0][this.timeZoneKey[v]].offset < 0 ? '-' : '+',
						timezone: this.timeZoneKey[v]
					});
				}
			}
			this.timezoneToSend = this.timeZoneKey[0];

		} else {
			if (this.timeZoneObjectToDisplay[this.timeZoneInitials] === undefined) {
				this.timeZoneObjectToDisplay[this.timeZoneInitials] = [];
			}
			this.timeZoneObjectToDisplay[this.timeZoneInitials].push({
				name: 'UTC',
				hours: 0,
				minutes: 0,
				dst: false,
				timeDelay: '',
				timezone: 'UTC'
			});
			this.timezoneToSend = 'UTC';
		}
	}

	prepareDate(subscriptionDate) {
		if (!subscriptionDate) {
			return '';
		} else {
			return moment(subscriptionDate).format('YYYY-MM-DD');
		}
	}

	prepareTime(timeInSeconds: number) {
		if (timeInSeconds === 30) {
			return (timeInSeconds).toString() + ' ' + 'seconds';
		} else if (timeInSeconds === 60) {
			return (timeInSeconds / 60).toString() + ' ' + 'minute';
		} else {
			return (timeInSeconds / 60).toString() + ' ' + 'minutes';
		}
	}

	mapClickHandler(event: google.maps.MapMouseEvent) {
		this.markerPosition = event.latLng;
	}

	markerDragHandler(event: google.maps.MapMouseEvent) {
		this.markerPosition = event.latLng;
	}

	checkSyncTime(syncTime: number) {
		if (syncTime === 0) {
			this.gatewayObj.syncTime = 1;
		} else {
			this.gatewayObj.syncTime = 0;
		}
	}

	checkHidden(hidden: number) {
		if (hidden === 0) {
			this.gatewayObj.hidden = 1;
		} else {
			this.gatewayObj.hidden = 0;
		}

	}

	verifySerialPaste() {
		setTimeout(() => this.verifySerial(), 500);
	}
	searchForAddress() {
		const fullAddress = this.searchLocationField;

		if (fullAddress.length <= 3) {
			return;
		}

		this.nominatimService.addressLookup(fullAddress).toPromise()
			.then((response) => {
				if (response && response[0]) {
					const {latitude, longitude} = response[0];
					this.mapCenter = this.getPositionObject(latitude, longitude);
					this.markerPosition = this.getPositionObject(latitude, longitude);
					this.mapZoomLevel = 16;
				} else {
					this.showAddressError = true;
				}
			})
			.catch((err) => {
				console.error('Got an error from Nominatim API: ' + err);
				this.showAddressError = true;
			});
	}
}
