import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '@svc/api.service';
import {AuthService} from '@svc/auth.service';

interface ApiKey {
	id: number;
	key: string;
	comment: string;
}

@Component({
	selector: 'kntz-profile-page',
	templateUrl: './profile.page.html',
	styleUrls: ['./profile.page.scss'],
	encapsulation: ViewEncapsulation.None
})
export class ProfilePageComponent implements OnInit {
	public editUserForm: FormGroup;
	public apiKeys: ApiKey[] = [];
	public apiKeysLoading = true;
	public apiKeysLoadError: string = null;
	public deletingApiKeyId = null;

	public newApiKeyComment = null;
	public newApiKey: ApiKey;

	public saveInProgress = false;
	public success = false;
	public error;

	private profileUrl = '/users/profile';
	private getApiKeysUrl = '/users/apiKeys';
	private createApiKeyUrl = '/users/apiKeys';
	private deleteApiKeyUrl = '/users/apiKey';

	constructor(
		private api: ApiService,
		public authSvc: AuthService,
	) {
	}

	async ngOnInit() {
		this.editUserForm = new FormGroup({
			currentPassword: new FormControl(null),
			password: new FormControl(null),
			confirmPassword: new FormControl(null),
			title: new FormControl(''),
			firstName: new FormControl(null, Validators.minLength(2)),
			lastName: new FormControl(null, Validators.minLength(2)),
			newApiKeyComment: new FormControl('')
		});

		await this.api.get(this.profileUrl).toPromise().then(response => {
			['title', 'firstName', 'lastName'].forEach((item) => {
				this.editUserForm.controls[item].setValue(response[item]);
			});
		}).catch(err => {
			alert('Error encountered while loading the data. Please retry later.');
		});

		this.newApiKey = undefined;
		this.getApiKeys();
	}

	emptyStringOnNull(value) {
		return value === null ? '' : value;
	}

	/**
     * Validates the password strength:
     * - 8 characters min
     * - at least one letter
     * - at least one capital letter
     * - at least one number
     * - at least one symbol
     * @param value
     */
	validPassword(value) {
		if (value.length < 8) {
			return false;
		}

		if (! /[a-z]/.test(value)) {
			return false;
		}
		if (! /[A-Z]/.test(value)) {
			return false;
		}

		if (! /[0-9]/.test(value)) {
			return false;
		}

		if (! /[^a-zA-Z0-9]/.test(value)) {
			return false;
		}

		return true;
	}

	onSubmit() {
		let data;

		this.success = false;
		this.error = null;

		const firstName = this.emptyStringOnNull(this.editUserForm.get('firstName').value);
		const lastName = this.emptyStringOnNull(this.editUserForm.get('lastName').value);
		const title = this.emptyStringOnNull(this.editUserForm.get('title').value);
		const currentPassword = this.emptyStringOnNull(this.editUserForm.get('currentPassword').value);
		const confirmPassword = this.emptyStringOnNull(this.editUserForm.get('confirmPassword').value);
		const password = this.emptyStringOnNull(this.editUserForm.get('password').value);

		if (currentPassword.length === 0) {
			this.error = 'The current password is mandatory';
			return;
		}

		if (password !== confirmPassword) {
			this.error = 'The passwords do not match';
			return;
		}

		if (password.length > 0) {
			if (!this.validPassword(password)) {
				this.error = 'The password must have at least 8 mixed case letters, numbers and symbols.';
				return;
			}
		}

		if (firstName.length === 0 || lastName.length === 0) {
			this.error = 'Name input is not valid';
			return;
		}

		this.saveInProgress = true;

		if (password.length === 0 && confirmPassword.length === 0) {
			data = {firstName, lastName, title, currentPassword};
		} else {
			data = {firstName, lastName, title, currentPassword, password};
			data['password_confirmation'] = confirmPassword;
		}

		this.api.patch(this.profileUrl, data).toPromise()
			.then(() => {
				this.success = true;

				this.editUserForm.controls['currentPassword'].setValue(null);
				this.editUserForm.controls['password'].setValue(null);
				this.editUserForm.controls['confirmPassword'].setValue(null);
			}).catch((err) => {
				if (err.status === 400) {
					this.error = 'Wrong current password';
				} else {
					this.error = 'Error saving the profile information';
				}
			})
			.finally(() => {
				this.saveInProgress = false;
			});
	}

	/**
	 * Gets the API keys for the current user
	 */
	getApiKeys() {
		if (this.authSvc.loggedUserRole === undefined) {
			setTimeout(() => {this.getApiKeys(); }, 200);
			return;
		}

		if (this.authSvc.loggedUserRole !== 2) {
			return;
		}

		this.apiKeysLoading = true;
		this.apiKeysLoadError = null;

		this.api.get(this.getApiKeysUrl).toPromise()
			.then((response) => {
				this.apiKeys = [];
				response.forEach((apiKey) => {
					if (this.newApiKey && apiKey.id === this.newApiKey.id) {
						this.apiKeys.push(this.newApiKey);
					} else {
						this.apiKeys.push(apiKey);
					}
				});
			})
			.catch(err => {
				this.apiKeysLoadError = 'Error encountered while loading the data. Please retry later.';
			})
			.finally(() => {
				this.apiKeysLoading = false;
			});
	}

	/**
	 * Creates a new API key
	 */
	createApiKey() {
		if (this.newApiKeyComment === null) {
			return;
		}

		this.apiKeysLoading = true;
		this.api.post(this.createApiKeyUrl, {comment: this.newApiKeyComment}).toPromise()
			.then((response) => {
				// this.apiKeys.push(response);
				this.newApiKey = response;
				this.newApiKeyComment = null;

				this.apiKeys.push({
					id: response.id,
					key: response.key.substr(0, 4) + '******' + response.key.substr(-4),
					comment: response.comment,
				});
			})
			.catch(err => {
				alert('Error encountered while creating the API key. Please retry later.');
			})
			.finally(() => {
				this.apiKeysLoading = false;
			});
	}

	/**
	 * Deletes an API key
	 * @param id
	 */
	deleteApiKey(id: number) {
		const index = this.apiKeys.findIndex(item => item.id === id);
		if (index === -1) {
			return;
		}

		if (confirm('Are you sure you want to delete this API key?')) {
			this.deletingApiKeyId = id;
			this.api.delete(`${this.deleteApiKeyUrl}/${id}`, {confirmation: 1}).toPromise()
				.then(() => {
					this.apiKeys.splice(index, 1);
					if (this.newApiKey && this.newApiKey.id === id) {
						this.newApiKey = null;
					}
				})
				.catch(err => {
					alert('Error encountered while deleting the API key. Please retry later.');
				})
				.finally(() => {
					this.deletingApiKeyId = null;
				});
		}
	}

	openAddApiKey() {
		this.newApiKeyComment = '';
	}

	closeAddApiKey() {
		this.newApiKeyComment = null;
	}

	setNewApiKeyComment($event) {
		this.newApiKeyComment = $event.target.value;
	}
}
