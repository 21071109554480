import { Component, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import {ProcessCellForExportParams} from 'ag-grid-community';
import { ColumnType } from '@models/column.types';
import { ButtonsRendererComponent } from '@comp/buttons.renderer/buttons.renderer.component';
import { GridiconsRendererComponent } from '@comp/grid.icons.renderer/grid.icons.renderer.component';
import { Router } from '@angular/router';
import { ApiService } from '@svc/api.service';
import { AgGridAngular } from 'ag-grid-angular';

@Component({
	selector: 'kntz-gateways-grid',
	templateUrl: './gateways-grid.component.html',
	styleUrls: ['./gateways-grid.component.scss'],
	encapsulation: ViewEncapsulation.None

})
export class GatewaysGridComponent {
	private gridApi;
	private gridColumnApi;
	public rowData;
	public getDataPath;
	public searchValue;
	@ViewChild('agGrid') agGrid: AgGridAngular;

	// security improvement to prevent CSV injections
	public exportParams = {
		processCellCallback(params: ProcessCellForExportParams): string {
			return params.value === undefined || params.value === null ? '' : (params.value + '').replace(/^([=+\-@\t\r])/, '\t$1');
		}
	};

	@Output() gridButtonClick = new EventEmitter<ColumnType>();

	@Input()
	set grid(value) {
		if (value) {
			this.rowData = value.rows;
		}
	}

	constructor(private router: Router,
				private api: ApiService) {
		this.getDataPath = function(data) {
			return data.orgHierarchy;
		};
	}

	public context = (this);

	public columnDefs = [
		{
			field: 'status',
			sortable: true,
			filter: false,
			getQuickFilterText: (params) => {
				if (params.data.statusInactive) {
					return 'inactive';
				} else if (params.data.statusOfflineGateway || params.data.statusOfflineGatewayOnline) {
					return 'offline';
				} else if (params.data.statusOnlineGateway) {
					return 'online';
				} else if (params.data.statusUploadingGateway) {
					return 'upload';
				}

				return ''; // not searchable in this case
			}, // search is implemented with quickfilter, which searches on all columns
			width: 100,
			minWidth: 100,
			type: 'customActions',
			cellRenderer: 'buttonsRenderer',
			cellRendererParams: {
				buttons: [
					{
						icon: 'fa-circle status-inactive' + ' ' + 'device',
						tooltip: 'Inactive',
						type: 'inactive',
						dataFieldCondition: 'statusInactive',
						isIcon: true
					},
					{
						icon: 'fa-circle status-offline' + ' ' + 'device',
						tooltip: 'Offline',
						type: 'offline',
						dataFieldCondition: 'statusOfflineGateway',
						isIcon: true
					}, {
						icon: 'fa-circle status-offline-gateway-online' + ' ' + 'device',
						tooltip: 'Offline but gateway online',
						type: 'offline',
						dataFieldCondition: 'statusOfflineGatewayOnline',
						isIcon: true,
					}, {
						icon: 'fa-circle status-online' + ' ' + 'device',
						tooltip: 'Online',
						type: 'online',
						dataFieldCondition: 'statusOnlineGateway',
						isIcon: true
					},
					{
						icon: 'fa-circle status-online-old-data' + ' ' + 'device',
						tooltip: 'Uploading historical data',
						type: 'historicData',
						dataFieldCondition: 'statusUploadingGateway',
						isIcon: true
					}]
			}

		},
		{
			field: 'serialNumber',
			sortable: true,
			width: 200,
			minWidth: 100,
		},
		{
			field: 'location',
			sortable: true,
			width: 200,
			minWidth: 100,
		},
		{
			field: 'swVersion',
			sortable: true,
			width: 200,
			minWidth: 100,
		},
		{
			field: 'type',
			hide: true,
		},
		{
			type: 'customActions',
			cellRenderer: 'buttonsRenderer',
			minWidth: 200,
			maxWidth: 200,
			flex: 1,
			headerName: 'Actions',
			resizable: false,
			filter: false,
			color: 'black',

			cellRendererParams: {
				buttons: [
					{
						icon: 'fa-edit',
						tooltip: 'Edit',
						type: 'settings',
						dataFieldCondition: 'settingsAllowed',
						isIcon: true
					},
					{
						icon: 'fa-trash',
						tooltip: 'Delete',
						type: 'delete',
						dataFieldCondition: 'deleteAllowed',
						isIcon: true
					},
					{
						icon: 'fa-wifi',
						tooltip: 'Wifi',
						type: 'wifi',
						dataFieldCondition: 'wifiAllowed',
						isIcon: true
					},
					{
						icon: 'fa-upload',
						tooltip: 'Upload',
						type: 'upload',
						dataFieldCondition: 'uploadAllowed',
						isIcon: true
					},
					{
						icon: 'fa-info-circle',
						tooltip: 'Show Data',
						type: 'details',
						dataFieldCondition: 'detailsAllowed',
						isIcon: true
					}
				]
			}
		}

	];

	public defaultColDef = {
		editable: false,
		suppressMovable: true,
		suppressPaste: true,
		resizable: true,
		flex: 1,
		filter: true,
		floatingFilter: false,
		suppressMenu: true,
		cellStyle: { 'background-color': '#050d18' },
	};

	public autoGroupColumnDef = {
		headerName: 'Name',
		minWidth: 100,
		resizable: true,
		cellRendererParams: { suppressCount: true },
		sortable: true,
		filter: 'agTextColumnFilter',
		filterParams: {
			filterOptions: ['contains']
		},
		suppressMenu: false,
	};

	public frameworkComponents = {
		buttonsRenderer: ButtonsRendererComponent,
		iconsRenderer: GridiconsRendererComponent
	};

	public refresh() {
		if (this.agGrid && this.agGrid.api) {
			if (this.agGrid.api.getDisplayedRowCount() > 0) {
				this.agGrid.api.ensureIndexVisible(0, 'top');
			}
			this.agGrid.api.purgeServerSideCache();
		}
	}

	onGridReady(params) {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
	}

	quickSearch() {
		this.gridApi.setQuickFilter(this.searchValue);
	}

	onButtonClicked(params, type) {
		const data = params.data;

		switch (type) {
			case 'delete':
				let promise: Promise<any>;
				let errorMessage: string;
				if (data.type === 'gateway') {
					promise = this.api.post('/gateways/delete', {gatewayId: data.id, confirmation: 1}).toPromise();
					errorMessage = 'Error encountered while trying to delete the gateway';
				} else if (data.type === 'n1') {
					promise = this.api.delete(`/n1/system/${data.id}`, {confirmation: 1}).toPromise();
					errorMessage = 'Error encountered while trying to delete the Neon1';
				} else if (data.type === 'system') {
					promise = this.api.delete(`/device/${data.id}`, {confirmation: 1}).toPromise();
					errorMessage = 'Error encountered while trying to delete the system';
				}

				promise
					.then(() => {
						this.router.routeReuseStrategy.shouldReuseRoute = () => false;
						this.router.navigate(['/gateways']).then();
					})
					.catch(() => {
						alert(errorMessage);
					});
				break;
			case 'wifi':
				break;
			case 'settings':
				this.router.navigate(['/gateways/edit', data.id]).then();
				break;
			case 'details':
				this.router.navigate(['/device/data', data.id]).then();
				break;
			case 'upload':
				this.router.navigate(['/gateways/uploadData', data.id]).then();
				break;
		}
	}

	onCellClicked($event: any) {
		if ($event.column.colDef.type === 'customActions') {
			return;
		}

		if ($event.data.type === 'system') {
			const deviceId = $event.data.id;
			this.router.navigate(['/device/data', deviceId]).then();
		} else {

		}
	}
}
