import {Injectable, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';

import {ROUTES} from '@const/routes';
import {ApiService} from './api.service';

import * as sha1 from 'js-sha1';
import {UserRole} from '@models/global.model';
import {sleep} from '../common';
import {jwtDecode} from 'jwt-decode';

export interface TokenResponse {
	access_token: string;
	token_type: string;
	expires_in: number;
	iperm?: boolean;
	role: UserRole;
	parent_id: number;
}

@Injectable({
	providedIn: 'root'
})
export class AuthService implements OnDestroy {
	private refreshTimer;

	private readonly JWT_TOKEN = 'JWT_TOKEN';
	private readonly JWT_TOKEN_EXPIRATION = 'JWT_TOKEN_EXPIRATION';
	public loggedUser: string;
	public loggedUserRole: UserRole;
	public loggedUserParentId: number;

	public acceptedGdpr: boolean;
	public refreshInProgress = false;

	public lastRoute = null;

	public permissions = {
		insights: false,
		insightsHealthStatus: false,
	};

	constructor(
		private apiSvc: ApiService,
		private router: Router
	) {
		if (this.isLoggedIn()) {
			const tokenExpirationTimeInSeconds = Math.floor((this.getJwtTokenExpiration() - Date.now()) / 1000);
			this.setupRefreshTimer(tokenExpirationTimeInSeconds);

			this.resetPermissions();
			this.parsePermissions(this.getJwtToken());

			if (this.loggedUser === undefined) {
				setTimeout(() => {
					this.getLoggedUser();
				}, 0);
			}
		}
	}

	ngOnDestroy() {
		this.cancelRefreshTimer();
	}

	/**
	 * Sets up the token refresh timer
	 * @param refreshTime
	 */
	setupRefreshTimer(refreshTime) {
		this.cancelRefreshTimer();

		this.refreshTimer = setTimeout(() => {
			this.refreshToken();
		}, (refreshTime - 20) * 1000);
	}

	/**
	 * Cancels the token refresh timer
	 */
	cancelRefreshTimer() {
		if (this.refreshTimer) {
			clearTimeout(this.refreshTimer);
			this.refreshTimer = null;
		}
	}

	/**
	 * Resets the permission object
	 */
	resetPermissions(): void {
		Object.keys(this.permissions).forEach((key) => {
			this.permissions[key] = false;
		});
	}

	/**
	 * Parses a token and extracts the permissions
	 * @param token
	 */
	parsePermissions(token: string): void {
		const jwtDecoded = jwtDecode(token);
		if (jwtDecoded['pe'] === undefined) {
			// we don't yet have the new token format
			const sha = localStorage.getItem('iperm');
			const token = localStorage.getItem(this.JWT_TOKEN);
			if (sha === null || token === null) {
				this.permissions.insights = false;
			} else {
				this.permissions.insights = (sha === sha1(token + '+'));
			}

			return;
		}

		this.permissions.insights = !!jwtDecoded['pe']['in'];
		this.permissions.insightsHealthStatus = !!jwtDecoded['pe']['hs'];
	}

	/**
	 * Logs in the user
	 * @param email
	 * @param password
	 * @param captcha
	 */
	login(email: string, password: string, captcha: string) {
		return new Promise((resolve, reject) => {
			const data = {
				email: email,
				password: password,
				captcha: captcha
			};
			this.apiSvc.post('/login', data).toPromise()
				.then((tokenResponse: TokenResponse) => {
					this.loggedUser = email;
					this.loggedUserRole = tokenResponse.role;
					this.loggedUserParentId = tokenResponse.parent_id;

					this.storeToken(tokenResponse.access_token, tokenResponse.expires_in);
					this.storeIperm(tokenResponse.access_token, !!tokenResponse.iperm);
					this.acceptedGdpr = undefined;
					this.setupRefreshTimer(tokenResponse.expires_in);

					this.resetPermissions();
					this.parsePermissions(tokenResponse.access_token);

					resolve();
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	/**
	 * Logs out the user
	 */
	logout(lastRoute = null) {
		this.apiSvc.post('/logout', {}).toPromise()
			.catch(err => {})
			.finally(() => {
				if (this.lastRoute === null) {
					this.lastRoute = {
						email: this.loggedUser,
						lastRoute: lastRoute,
					};
				}
				this.loggedUser = null;
				this.removeTokens();
				this.resetPermissions();

				this.router.navigate([ROUTES.default]).then();
				this.acceptedGdpr = undefined;
			});
	}

	/**
	 * Refreshes the JWT token
	 */
	refreshToken() {
		if (this.refreshInProgress ) {
			return;
		}
		this.refreshInProgress = true;
		// localStorage.setItem('LAST_ROUTE', this.router.url);

		const body = { refreshToken: this.getJwtToken() };
		this.apiSvc.post('/refreshToken', body).toPromise()
			.then((tokenResponse) => {
				this.storeToken(tokenResponse.access_token, tokenResponse.expires_in);
				this.storeIperm(tokenResponse.access_token, !!tokenResponse.iperm);
				this.setupRefreshTimer(tokenResponse.expires_in);

				this.resetPermissions();
				this.parsePermissions(tokenResponse.access_token);
			})
			.catch((err) => {
				this.logout(this.router.url);
			})
			.finally(() => {
				this.refreshInProgress = false;
			});
	}

	/**
	 * Returns true if the user is logged in
	 */
	isLoggedIn(): boolean {
		return !!this.getJwtToken();
	}

	/**
	 * Returns the JWT token
	 */
	getJwtToken(): string {
		return localStorage.getItem(this.JWT_TOKEN);
	}

	/**
	 * Returns the JWT token expiration time
	 */
	getJwtTokenExpiration(): number {
		const expiration = localStorage.getItem(this.JWT_TOKEN_EXPIRATION);
		if ( ! expiration ) {
			return Date.now() + 3600 * 1000;
		}

		return parseInt(expiration, 10);
	}

	// isGdprAccepted(): boolean {
	// 	this.apiSvc.get('/gdpr/status').toPromise().then(response => {
	// 		return response.consent;
	// 	});
	// 	return false;
	// }

	updateGdprStatus(status: number): void {
		this.apiSvc.post('/gdpr/status', {'consent': status}).toPromise().then(() => this.acceptedGdpr = status !== 0);
	}

	/**
	 * Stores the JWT token
	 * @param token
	 * @param expiresInSeconds Number of seconds for token expiration
	 * @private
	 */
	private storeToken(token: string, expiresInSeconds: number) {
		localStorage.setItem(this.JWT_TOKEN, token);
		localStorage.setItem(this.JWT_TOKEN_EXPIRATION, (Date.now() + expiresInSeconds * 1000) + '');
	}

	/**
	 * Removes the JWT tokens
	 * @private
	 */
	private removeTokens() {
		localStorage.removeItem(this.JWT_TOKEN);
		localStorage.removeItem(this.JWT_TOKEN_EXPIRATION);
	}

	/**
	 * Stores the insights permission
	 * @param token
	 * @param iperm
	 */
	public storeIperm(token, iperm: boolean) {
		const str = token + (iperm ? '+' : '-');
		const sha = sha1(str);

		localStorage.setItem('iperm', sha);
	}

	/**
	 * Returns true if the logged in user has insights permission
	 */
	public haveInsightsPermission(): boolean {
		return this.permissions.insights;
	}

	/**
	 * Returns true if the use has Health Status permission
	 */
	public haveInsightsHealthStatusPermission(): boolean {
		return this.permissions.insightsHealthStatus;
	}

	public async haveReportMeasurementsPermission(): Promise<boolean> {
		while (this.loggedUser === undefined) {
			await new Promise((resolve) => {
				setTimeout(() => { resolve(); }, 200);
			});
		}
		return (this.loggedUserParentId === 0 && this.loggedUserRole === 2) || (this.loggedUser.toLowerCase() === 'l.teeselink@hellebrekers.nl');
	}

	public async haveStatsPermission(): Promise<boolean> {
		while (this.loggedUser === undefined) {
			await new Promise((resolve) => {
				setTimeout(() => { resolve(); }, 200);
			});
		}
		return (this.loggedUserParentId === 0 && this.loggedUserRole === 2);
	}

	/**
	 * Gets the currently logged-in user
	 */
	public getLoggedUser() {
		if (this.loggedUser) {
			return;
		}

		this.apiSvc.get('/me', {'_': Date.now()}).toPromise()
			.then((response) => {
				this.loggedUser = response.email ? response.email : null;
				this.loggedUserRole = response.role;
				this.loggedUserParentId = response.parent_id;
			});
	}

	public async waitForUserData() {
		let waitCounter = 0;
		while (this.loggedUser === undefined) {
			if (waitCounter > 10) {
				throw Error('Waited too long for the user data to come.');
			}
			await sleep(500);
			waitCounter++;
		}
	}

	/**
	 * Returns true if the logged in user is an administrator
	 */
	public isAdministrator(): boolean {
		return this.isLoggedIn() && this.loggedUserRole === UserRole.ADMIN;
	}
}
