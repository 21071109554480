import {DeviceStatusType, Device} from '@comp/device/device.model';
import {MOCK_NAVBAR, Navbar} from '@comp/navbar/navbar.model';


export interface DeviceHomePage {
	id: number;
	gatewayId: number;
	serial: string;
	status: string;
	name: string;
	city: string;
}

export interface N1DeviceHomePage {
	id: string;
	serial: string;
	status: string;
	name: string;
	city: string;
}

export interface Marker {
	'latitude': number;
	'longitude': number;
	'position': { lat: number, lng: number };
	'name': 'string';
	'city': 'string';
	'status': 'string';
	'devices': [
		{
			'id': number,
			'gatewayId': number,
			'serial': 'string',
			'name': 'string',
			'status': 'string',
			'city': 'string'
		}
	];
	'gatewayId': 465;
}

export interface N1Marker {
	latitude: number;
	longitude: number;
	name: string;
	city: string;
	status: string;
	devices: string[];
}

export interface HomePageDataModel {
	Header: Navbar;
	Map: string;
	Locations: Array<Device | CityRow>;
}

export interface CityRow {
	Type: string;
	Name: string;
}

export enum LocationType {
	City = 'City',
	Device = 'Device'
}

export interface DashboardPageModel {
	Id: number;
	Name: string;
	OnlineStatus: string;
	AlarmStatus: string;
}

export interface DashboardModel {
	Id: number;
	UserId: number;
	Name: string;
	FilterSystemsWithAlarms: number;
	FilterOfflineSystems: number;
	TotalSystems: number;
	OnlineSystems: number;
	Systems: number[];
}

export const MOCK_DASHBOARD_PAGE_DATA: DashboardPageModel[] = [
	{
		Id: 1,
		Name: 'test dashboard',
		OnlineStatus: '1/2',
		AlarmStatus: '1'
	},
	{
		Id: 2,
		Name: 'dashboard 2',
		OnlineStatus: '1/1',
		AlarmStatus: '3'
	}
];

export const GDPRText = {};


// export const MOCK_HOME_PAGE_DATA: HomePageDataModel = {
// 	Header: MOCK_NAVBAR,
// 	Map: 'Map',
// 	Locations: [
// 		{
// 			Type: 'City' as LocationType,
// 			Name: 'Alloway'
// 		},
// 		{
// 			Type: 'Device' as LocationType,
// 			Id: '001',
// 			Notifications: false,
// 			Name: {
// 				Title: 'Craig Tara',
// 				Route: '/device/data'
// 			},
// 			Status: 'Online' as DeviceStatusType,
// 			DataIndicators: [
// 				{
// 					Title: 'Cl12',
// 					Canonical: 'cl12',
// 					Value: '0.00',
// 					Unit: 'mg/l',
// 					Enabled: true,
// 					MeasurementNumber: 1
// 				},
// 				{
// 					Title: 'pH',
// 					Canonical: 'ph',
// 					Value: '6.96',
// 					Unit: 'ph',
// 					Enabled: true,
// 					MeasurementNumber: 1
// 				},
// 				{
// 					Title: 'Redox',
// 					Canonical: 'redox',
// 					Value: '-1385',
// 					Unit: 'mV',
// 					Enabled: true,
// 					MeasurementNumber: 1
// 				},
// 				{
// 					Title: 'Temperature',
// 					Canonical: 'Temperature',
// 					Value: '25.0',
// 					Unit: '°C',
// 					Enabled: true,
// 					MeasurementNumber: 1
// 				}
// 			],
// 			DeviceMenu: [
// 				{
// 					Title: 'Show Data',
// 					Route: '/device/data/{deviceId}'
// 				},
// 				{
// 					Title: 'Reports',
// 					Route: '/device/reports/{deviceId}'
// 				},
// 				{
// 					Title: 'Settings',
// 					Route: '/device/settings/{deviceId}'
// 				},
// 				{
// 					Title: 'Notifications',
// 					Route: '/notifications/{deviceId}/'
// 				}
// 			]
// 		}
// 		,
// 		{
// 			Type: 'City' as LocationType,
// 			Name: 'Blackpool'
// 		},
// 		{
// 			Type: 'Device' as LocationType,
// 			Id: '002',
// 			Notifications: false,
// 			Name: {
// 				Title: 'BZ Lion',
// 				Route: '/device/data'
// 			},
// 			Status: 'Online' as DeviceStatusType,
// 			DataIndicators: [
// 				{
// 					Title: 'Cl12',
// 					Canonical: 'cl12',
// 					Value: '2.35',
// 					Unit: 'mg/l',
// 					Enabled: true,
// 					MeasurementNumber: 1
// 				},
// 				{
// 					Title: 'pH',
// 					Canonical: 'ph',
// 					Value: '6.96',
// 					Unit: 'pH',
// 					Enabled: true,
// 					MeasurementNumber: 1
// 				},
// 				{
// 					Title: 'Redox',
// 					Canonical: 'redox',
// 					Value: '-1385',
// 					Unit: 'mV',
// 					Enabled: true,
// 					MeasurementNumber: 1
// 				},
// 				{
// 					Title: 'Temperature',
// 					Canonical: 'temp',
// 					Value: '25.0',
// 					Unit: '°C',
// 					Enabled: true,
// 					MeasurementNumber: 1
// 				}
// 			],
// 			DeviceMenu: [
// 				{
// 					Title: 'Show Data',
// 					Route: '/device/data/{deviceId}'
// 				},
// 				{
// 					Title: 'Reports',
// 					Route: '/device/reports/{deviceId}'
// 				},
// 				{
// 					Title: 'Settings',
// 					Route: '/device/settings/{deviceId}'
// 				},
// 				{
// 					Title: 'Notifications',
// 					Route: '/notifications/{deviceId}/'
// 				}
// 			]
// 		},
// 		{
// 			Type: 'Device' as LocationType,
// 			Id: '003',
// 			Notifications: false,
// 			Name: {
// 				Title: 'Penguin',
// 				Route: '/device/data'
// 			},
// 			Status: 'Online' as DeviceStatusType,
// 			DataIndicators: [
// 				{
// 					Title: 'Cl12',
// 					Canonical: 'cl12',
// 					Value: '0.00',
// 					Unit: 'mg/l',
// 					Enabled: true,
// 					MeasurementNumber: 1
// 				},
// 				{
// 					Title: 'pH',
// 					Canonical: 'ph',
// 					Value: '6.96',
// 					Unit: 'ph',
// 					Enabled: true,
// 					MeasurementNumber: 1
// 				},
// 				{
// 					Title: 'Redox',
// 					Canonical: 'redox',
// 					Value: '-1385',
// 					Unit: 'mV',
// 					Enabled: true,
// 					MeasurementNumber: 1
// 				}
// 				// {
// 				// 	Title: 'Temperature',
// 				// 	Type: 'Temperature',
// 				// 	Value: '25.0',
// 				// 	Unit: '°C',
// // Enabled: true,
// // MeasurementNumber: 1,
// 				// }
// 			],
// 			DeviceMenu: [
// 				{
// 					Title: 'Show Data',
// 					Route: '/device/data/{deviceId}'
// 				},
// 				{
// 					Title: 'Reports',
// 					Route: '/device/reports/{deviceId}'
// 				},
// 				{
// 					Title: 'Settings',
// 					Route: '/device/settings/{deviceId}'
// 				},
// 				{
// 					Title: 'Notifications',
// 					Route: '/notifications/{deviceId}/'
// 				}
// 			]
// 		},
// 		{
// 			Type: 'City' as LocationType,
// 			Name: 'Bradford'
// 		},
// 		{
// 			Type: 'Device' as LocationType,
// 			Id: '004',
// 			Notifications: false,
// 			Name: {
// 				Title: 'BGGS M/P',
// 				Route: '/device/data'
// 			},
// 			Status: 'Online' as DeviceStatusType,
// 			DataIndicators: [
// 				// {
// 				// 	Title: 'Cl12',
// 				// 	Type: 'Cl12',
// 				// 	Value: '0.00',
// 				// 	Unit: 'mg/l',
// // Enabled: true,
// // MeasurementNumber: 1,
// 				// },
// 				{
// 					Title: 'pH',
// 					Canonical: 'ph',
// 					Value: '6.96',
// 					Unit: 'ph',
// 					Enabled: true,
// 					MeasurementNumber: 1
// 				},
// 				{
// 					Title: 'Redox',
// 					Canonical: 'redox',
// 					Value: '-1385',
// 					Unit: 'mV',
// 					Enabled: true,
// 					MeasurementNumber: 1
// 				},
// 				{
// 					Title: 'Temperature',
// 					Canonical: 'temp',
// 					Value: '25.0',
// 					Unit: '°C',
// 					Enabled: true,
// 					MeasurementNumber: 1
// 				}
// 			],
// 			DeviceMenu: [
// 				{
// 					Title: 'Show Data',
// 					Route: '/device/data/{deviceId}'
// 				},
// 				{
// 					Title: 'Reports',
// 					Route: '/device/reports/{deviceId}'
// 				},
// 				{
// 					Title: 'Settings',
// 					Route: '/device/settings/{deviceId}'
// 				},
// 				{
// 					Title: 'Notifications',
// 					Route: '/notifications/{deviceId}/'
// 				}
// 			]
// 		},
// 		{
// 			Type: 'Device' as LocationType,
// 			Id: '005',
// 			Notifications: false,
// 			Name: {
// 				Title: 'St Bedes',
// 				Route: '/device/data'
// 			},
// 			Status: 'Online' as DeviceStatusType,
// 			DataIndicators: [
// 				{
// 					Title: 'Cl12',
// 					Canonical: 'cl12',
// 					Value: '4.44',
// 					Unit: 'mg/l',
// 					Enabled: true,
// 					MeasurementNumber: 1
// 				},
// 				{
// 					Title: 'pH',
// 					Canonical: 'ph',
// 					Value: '6.96',
// 					Unit: 'ph',
// 					Enabled: true,
// 					MeasurementNumber: 1
// 				},
// 				// {
// 				// 	Title: 'Redox',
// 				// 	Type: 'Redox',
// 				// 	Value: '-1000',
// 				// 	Unit: 'mV',
// // Enabled: true,
// // MeasurementNumber: 1,
// 				// },
// 				{
// 					Title: 'Temperature',
// 					Canonical: 'temp',
// 					Value: '25.0',
// 					Unit: '°C',
// 					Enabled: true,
// 					MeasurementNumber: 1
// 				}
// 			],
// 			DeviceMenu: [
// 				{
// 					Title: 'Show Data',
// 					Route: '/device/data/{deviceId}'
// 				},
// 				{
// 					Title: 'Reports',
// 					Route: '/device/reports/{deviceId}'
// 				},
// 				{
// 					Title: 'Settings',
// 					Route: '/device/settings/{deviceId}'
// 				},
// 				{
// 					Title: 'Notifications',
// 					Route: '/notifications/{deviceId}/'
// 				}
// 			]
// 		},
// 		{
// 			Type: 'Device' as LocationType,
// 			Id: '006',
// 			Notifications: false,
// 			Name: {
// 				Title: 'Bradford U',
// 				Route: '/device/data'
// 			},
// 			Status: 'Online' as DeviceStatusType,
// 			DataIndicators: [
// 				{
// 					Title: 'Cl12',
// 					Canonical: 'cl12',
// 					Value: '0.00',
// 					Unit: 'mg/l',
// 					Enabled: true,
// 					MeasurementNumber: 1
// 				},
// 				{
// 					Title: 'pH',
// 					Canonical: 'ph',
// 					Value: '6.96',
// 					Unit: 'ph',
// 					Enabled: true,
// 					MeasurementNumber: 1
// 				},
// 				{
// 					Title: 'Redox',
// 					Canonical: 'redox',
// 					Value: '-1385',
// 					Unit: 'mV',
// 					Enabled: true,
// 					MeasurementNumber: 1
// 				},
// 				{
// 					Title: 'Temperature',
// 					Canonical: 'temp',
// 					Value: '25.0',
// 					Unit: '°C',
// 					Enabled: true,
// 					MeasurementNumber: 1
// 				}
// 			],
// 			DeviceMenu: [
// 				{
// 					Title: 'Show Data',
// 					Route: '/device/data/{deviceId}'
// 				},
// 				{
// 					Title: 'Reports',
// 					Route: '/device/reports/{deviceId}'
// 				},
// 				{
// 					Title: 'Settings',
// 					Route: '/device/settings/{deviceId}'
// 				},
// 				{
// 					Title: 'Notifications',
// 					Route: '/notifications/{deviceId}/'
// 				}
// 			]
// 		},
// 		{
// 			Type: 'City' as LocationType,
// 			Name: 'Cardiff'
// 		},
// 		{
// 			Type: 'Device' as LocationType,
// 			Id: '007',
// 			Notifications: false,
// 			Name: {
// 				Title: 'Radyr Scho',
// 				Route: '/device/data'
// 			},
// 			Status: 'Online' as DeviceStatusType,
// 			DataIndicators: [
// 				{
// 					Title: 'Cl12',
// 					Canonical: 'cl12',
// 					Value: '0.00',
// 					Unit: 'mg/l',
// 					Enabled: true,
// 					MeasurementNumber: 1
// 				},
// 				{
// 					Title: 'pH',
// 					Canonical: 'ph',
// 					Value: '6.96',
// 					Unit: 'ph',
// 					Enabled: true,
// 					MeasurementNumber: 1
// 				},
// 				{
// 					Title: 'Redox',
// 					Canonical: 'redox',
// 					Value: '-1385',
// 					Unit: 'mV',
// 					Enabled: true,
// 					MeasurementNumber: 1
// 				},
// 				{
// 					Title: 'Temperature',
// 					Canonical: 'temp',
// 					Value: '25.0',
// 					Unit: '°C',
// 					Enabled: true,
// 					MeasurementNumber: 1
// 				}
// 			],
// 			DeviceMenu: [
// 				{
// 					Title: 'Show Data',
// 					Route: '/device/data/{deviceId}'
// 				},
// 				{
// 					Title: 'Reports',
// 					Route: '/device/reports/{deviceId}'
// 				},
// 				{
// 					Title: 'Settings',
// 					Route: '/device/settings/{deviceId}'
// 				},
// 				{
// 					Title: 'Notifications',
// 					Route: '/notifications/{deviceId}/'
// 				}
// 			]
// 		},
// 		{
// 			Type: 'Device' as LocationType,
// 			Id: '008',
// 			Notifications: false,
// 			Name: {
// 				Title: 'DL M Pool',
// 				Route: '/device/data'
// 			},
// 			Status: 'Online' as DeviceStatusType,
// 			DataIndicators: [
// 				{
// 					Title: 'Cl12',
// 					Canonical: 'cl12',
// 					Value: '0.00',
// 					Unit: 'mg/l',
// 					Enabled: true,
// 					MeasurementNumber: 1
// 				},
// 				{
// 					Title: 'pH',
// 					Canonical: 'ph',
// 					Value: '6.96',
// 					Unit: 'ph',
// 					Enabled: true,
// 					MeasurementNumber: 1
// 				},
// 				{
// 					Title: 'Redox',
// 					Canonical: 'redox',
// 					Value: '-1385',
// 					Unit: 'mV',
// 					Enabled: true,
// 					MeasurementNumber: 1
// 				},
// 				{
// 					Title: 'Temperature',
// 					Canonical: 'temp',
// 					Value: '25.0',
// 					Unit: '°C',
// 					Enabled: true,
// 					MeasurementNumber: 1
// 				}
// 			],
// 			DeviceMenu: [
// 				{
// 					Title: 'Show Data',
// 					Route: '/device/data/{deviceId}'
// 				},
// 				{
// 					Title: 'Reports',
// 					Route: '/device/reports/{deviceId}'
// 				},
// 				{
// 					Title: 'Settings',
// 					Route: '/device/settings/{deviceId}'
// 				},
// 				{
// 					Title: 'Notifications',
// 					Route: '/notifications/{deviceId}/'
// 				}
// 			]
// 		},
// 		{
// 			Type: 'Device' as LocationType,
// 			Id: '009',
// 			Notifications: false,
// 			Name: {
// 				Title: 'DL Spa',
// 				Route: '/device/data'
// 			},
// 			Status: 'Online' as DeviceStatusType,
// 			DataIndicators: [
// 				{
// 					Title: 'Cl12',
// 					Canonical: 'cl12',
// 					Value: '0.00',
// 					Unit: 'mg/l',
// 					Enabled: true,
// 					MeasurementNumber: 1
// 				},
// 				{
// 					Title: 'pH',
// 					Canonical: 'ph',
// 					Value: '6.96',
// 					Unit: 'ph',
// 					Enabled: true,
// 					MeasurementNumber: 1
// 				},
// 				{
// 					Title: 'Redox',
// 					Canonical: 'redox',
// 					Value: '-1385',
// 					Unit: 'mV',
// 					Enabled: true,
// 					MeasurementNumber: 1
// 				},
// 				{
// 					Title: 'Temperature',
// 					Canonical: 'temp',
// 					Value: '25.0',
// 					Unit: '°C',
// 					Enabled: true,
// 					MeasurementNumber: 1
// 				}
// 			],
// 			DeviceMenu: [
// 				{
// 					Title: 'Show Data',
// 					Route: '/device/data/{deviceId}'
// 				},
// 				{
// 					Title: 'Reports',
// 					Route: '/device/reports/{deviceId}'
// 				},
// 				{
// 					Title: 'Settings',
// 					Route: '/device/settings/{deviceId}'
// 				},
// 				{
// 					Title: 'Notifications',
// 					Route: '/notifications/{deviceId}/'
// 				}
// 			]
// 		},
//
// 		{
// 			Type: 'City' as LocationType,
// 			Name: 'Crawley'
// 		},
// 		{
// 			Type: 'Device' as LocationType,
// 			Id: '010',
// 			Notifications: false,
// 			Name: {
// 				Title: 'Manor Grn',
// 				Route: '/device/data'
// 			},
// 			Status: 'Online' as DeviceStatusType,
// 			DataIndicators: [
// 				{
// 					Title: 'Cl12',
// 					Canonical: 'cl12',
// 					Value: '0.00',
// 					Unit: 'mg/l',
// 					Enabled: true,
// 					MeasurementNumber: 1
// 				},
// 				{
// 					Title: 'pH',
// 					Canonical: 'ph',
// 					Value: '6.96',
// 					Unit: 'ph',
// 					Enabled: true,
// 					MeasurementNumber: 1
// 				},
// 				{
// 					Title: 'Redox',
// 					Canonical: 'redox',
// 					Value: '-1385',
// 					Unit: 'mV',
// 					Enabled: true,
// 					MeasurementNumber: 1
// 				},
// 				{
// 					Title: 'Temperature',
// 					Canonical: 'temp',
// 					Value: '25.0',
// 					Unit: '°C',
// 					Enabled: true,
// 					MeasurementNumber: 1
// 				}
// 			],
// 			DeviceMenu: [
// 				{
// 					Title: 'Show Data',
// 					Route: '/device/data/{deviceId}'
// 				},
// 				{
// 					Title: 'Reports',
// 					Route: '/device/reports/{deviceId}'
// 				},
// 				{
// 					Title: 'Settings',
// 					Route: '/device/settings/{deviceId}'
// 				},
// 				{
// 					Title: 'Notifications',
// 					Route: '/notifications/{deviceId}/'
// 				}
// 			]
// 		}
// 	]
// };
