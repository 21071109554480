import { GridHeaderRendererComponent } from '@comp/grid.header.renderer/grid.header.renderer.component';

export interface DeviceDetails {
	id: number;
	gatewayId: number;
	serial: string;
	modbusChannel: number;
	name: string;
	deviceApplicationId: number;
	type: string;
	marketingType: string;
	lastActive: string;
	firstReading: string;
	lastReading: string;
	softwareVersion: string;
	hardwareVersion: string;
	status: string;
	hasOutstandingAlarm: boolean;
	activeOptions: number;
	activeOptionsBinary: string;
}

export interface Gateway {
	id: number;
	name: string;
	serial: string;
	latitude: number;
	longitude: number;
	city: string;
	timeZone: string;
	timeZoneOffset: number;
	active: number;
	uploadInterval: number;
	company: string;
	ip: string;
	softwareVersion: string;
	status: string;
	lastActive: string;
}

export enum ComingGoing {
	coming = 'coming',
	going = 'going'
}

export interface DeviceDetailRows {
	date: string;
	comingGoing: string;
	event: string;
	alarm: string;

}

export const eventsColumns: any = [
	{
		field: 'date',
		headerName: 'Date',
		sortable: true,
		filter: false,
		minWidth: 180,
		width: 180,
		tooltipField: 'dateSystem'
	},
	{
		field: 'comingGoing',
		headerComponentFramework: GridHeaderRendererComponent,
		cellRenderer: 'iconsRenderer',
		filter: false,
		sortable: true,
		minWidth: 140,
		maxWidth: 180
	},
	{
		field: 'event',
		headerName: 'Event',
		sortable: true,
		minWidth: 120,
		filter: 'agTextColumnFilter',
		filterParams: {
			suppressAndOrCondition: true,
			filterOptions: ['contains']
		},
		flex: 1
	},
	{
		field: 'alarm',
		headerName: 'Alarm',
		cellRenderer: 'iconsRenderer',
		filter: false,
		sortable: true,
		width: 100,
		maxWidth: 100
	}
];

export let calibrationColumns: any = [
	{
		field: 'date',
		headerName: 'Date',
		minWidth: 180,
		width: 180,
		filter: false,
		sortable: true,
		tooltipField: 'dateSystem',
		editable: false
	},
	{
		field: 'type',
		headerName: 'Type',
		width: 150,
		maxWidth: 150,
		filter: 'agTextColumnFilter',
		filterParams: {
			suppressAndOrCondition: true,
			filterOptions: ['contains']
		},
		flex: 1,
		sortable: true,
		editable: false
	},
	{
		field: 'slope',
		headerName: 'Slope',
		width: 100,
		maxWidth: 100,
		filter: false,
		sortable: true,
		singleClickEdit: true,
		cellEditorParams: { maxLength: 5 },
		enableCellChangeFlash: true
	},
	{
		field: 'zeropoint',
		headerName: 'Zeropoint',
		filter: false,
		sortable: true,
		width: 100,
		maxWidth: 100,
		singleClickEdit: true,
		cellEditorParams: { maxLength: 5 },
		enableCellChangeFlash: true
	},
	{
		field: 'offset',
		headerName: 'Offset',
		filter: false,
		sortable: false,
		width: 100,
		maxWidth: 100,
		singleClickEdit: false,
		cellEditorParams: { maxLength: 5 },
		enableCellChangeFlash: true,
		hide: true,
	},
	{
		field: 'phIso',
		headerName: 'pH Iso',
		sortable: false,
		filter: false,
		width: 80,
		maxWidth: 80,
		hide: true,
		singleClickEdit: true,
		cellEditorParams: { maxLength: 5 },
		enableCellChangeFlash: true
	},
	{
		field: 'phDamping',
		headerName: 'pH Damping',
		sortable: false,
		filter: false,
		width: 80,
		maxWidth: 80,
		hide: true,
		singleClickEdit: true,
		cellEditorParams: { maxLength: 5 },
		enableCellChangeFlash: true
	},
	{
		field: 'phAtCalibration',
		headerName: 'pH at DIS Cal.',
		sortable: false,
		filter: false,
		width: 180,
		maxWidth: 180,
		tooltipField: 'phAtCalibration',
		editable: false,
		hide: true,
	},

	{
		type: 'customActions',
		cellRenderer: 'buttonsRenderer',
		minWidth: 240,
		headerName: '',
		resizable: true,
		filter: false,
		editable: false,
		color: 'black',

		cellRendererParams: {
			buttons: [
				{
					icon: '',
					tooltip: 'Save',
					type: 'save',
					dataFieldCondition: 'saveAllowed',
					isIcon: false
				},
				{
					icon: '',
					tooltip: 'Cancel',
					type: 'cancel',
					dataFieldCondition: 'cancelAllowed',
					isIcon: false
				}, {
					icon: '',
					tooltip: 'Reset',
					type: 'reset',
					dataFieldCondition: 'resetAllowed',
					isIcon: false
				}
			]
		}
	},
	{
		field: 'message',
		headerName: '',
		sortable: true,
		filter: false,
		flex: 1,
		tooltipField: 'message',
		editable: false
	}
];
