import {Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import { ApiService } from '@svc/api.service';
import { EditSpecificGatewayModel } from '@pages/edit-specific-gateway/edit-specific-gateway.model';
import moment from 'moment';
import {KuntzeMapStyle} from '@pages/home/map.model';

import {NominatimService} from '@svc/nominatim.service';
import {filter} from "rxjs/operators";
import {Subscription} from "rxjs";
import Marker = google.maps.Marker;

@Component({
	selector: 'kntz-edit-specific-gateway',
	templateUrl: './edit-specific-gateway.page.html',
	styleUrls: ['./edit-specific-gateway.page.scss'],
	encapsulation: ViewEncapsulation.None
})
export class EditSpecificGatewayPageComponent implements OnDestroy {
	@ViewChild('marker') marker: Marker;

	public mapOptions: google.maps.MapOptions = {
		styles: KuntzeMapStyle,
		fullscreenControl: false,
		mapTypeControl: false,
		panControl: false,
	};
	public mapCenter: google.maps.LatLng;
	public mapZoomLevel = 18;

	public markerPosition: google.maps.LatLng;
	public markerOptions: google.maps.MarkerOptions = {
		draggable: true,
		icon: 'assets/images/marker_' + 'red' + '.png',
	};

	public subscriptionDate: string;
	public installationDate: string;
	public id: string;
	public errorCity: boolean;
	public errorName: boolean;
	public gatewayObj: EditSpecificGatewayModel = {
		serial: '',
		name: '',
		city: '',
		syncTime: 0,
		uploadInterval: 0,
		hidden: 0,
		subscriptionDate: '',
		installationDate: null,
		timezone: '',
		companyId: 0
	};
	public companies = [];
	public errorCompany: boolean;
	public companyName: string;
	public companiesNames = [];

	public countries: string[];
	public timeZoneKey: string[];
	public countriesObject: any;
	public uploadInterval: string;
	public uploadIntervals = ['30 seconds', '1 minute', '2 minutes', '3 minutes', '5 minutes', '7 minutes', '10 minutes', '15 minutes'];
	public timeZoneInitials: string;

	public timeZoneObjectToDisplay = {};
	public country: string;
	public timezones: any;
	public tmKey: any;

	public userCanChangeSubscriptionDate: boolean;
	public userHasEditUploadTimingPermission: boolean;

	public timezoneToSend: string;
	public subscriptionDateToSend: any;
	private installationDateToSend: any;
	public uploadIntervalToSend: number;

	public searchLocationField: any;
	public showAddressError: boolean;

	private readonly navigationSubscription$: Subscription;

	public dataLoaded = false;
	public saveInProgress = false;

	public isGateway = false;
	public isNeon1 = false;

	constructor(private route: ActivatedRoute,
				private api: ApiService,
				private router: Router,
				private nominatimService: NominatimService,
	) {
		this.navigationSubscription$ = router.events.pipe(
			filter(event => event instanceof NavigationEnd)
		).subscribe(() => {
			this.changeDeviceTo(this.route.snapshot.params['gatewayId']).then();
		});
	}

	ngOnDestroy() {
		if (this.navigationSubscription$) {
			this.navigationSubscription$.unsubscribe();
		}
	}

	async changeDeviceTo(deviceId: string) {
		if (/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/.test(deviceId)) {
			this.isNeon1 = true;
		} else {
			this.isGateway = true;
		}

		this.id = deviceId;
		await this.getGatewayDetails();
	}

	getPositionObject(latitude: number, longitude: number): google.maps.LatLng {
		return new google.maps.LatLng(latitude, longitude);
	}

	async getGatewayDetails() {
		this.dataLoaded = false;

		const url = this.isNeon1 ? '/n1/gateways/details/' + this.id : '/gateways/details/' + this.id;
		const response = await this.api.get(url).toPromise();

		this.gatewayObj = this.isNeon1 ? response.n1 : response.gateway;

		this.uploadInterval = this.prepareTime(this.gatewayObj.uploadInterval);
		this.subscriptionDate = this.prepareDate(this.gatewayObj.subscriptionDate);
		this.installationDate = this.prepareDate(this.gatewayObj.installationDate);

		this.subscriptionDateToSend = (this.gatewayObj.subscriptionDate === '' || this.gatewayObj.subscriptionDate === null) ?
			'' : moment(this.gatewayObj.subscriptionDate).format('YYYY-MM-DD');
		this.installationDateToSend = (this.gatewayObj.installationDate === '' || this.gatewayObj.installationDate === null) ?
			'' : moment(this.gatewayObj.installationDate).format('YYYY-MM-DD');

		if (this.isGateway) {
			this.countriesObject = response.countries;
			this.countries = Object.keys(response.countries);
			this.timezones = response.timezones;

			this.createTimeZoneObject();
		}

		if (response.markers.length) {
			const {latitude, longitude} = response.markers[0];
			this.mapCenter = this.getPositionObject(latitude, longitude);
			this.markerPosition = this.getPositionObject(latitude, longitude);
		}

		this.userCanChangeSubscriptionDate = response.userCanChangeSubscriptionDate;
		this.userHasEditUploadTimingPermission = response.userHasEditUploadTimingPermission;
		this.getCompanies();

		setTimeout(() => {
			this.dataLoaded = true;
		}, 0);
	}

	dataPickSubscription(event) {
		this.subscriptionDateToSend = '';
		if (event === null || /invalid/i.test(event.toString()) || event.toString().length === 0) {
			return;
		}

		this.subscriptionDateToSend = moment(event).format('YYYY-MM-DD');
	}

	dataPickInstallation(event) {
		this.installationDateToSend = '';
		if (event === null || /invalid/i.test(event.toString()) || event.toString().length === 0) {
			return;
		}

		this.installationDateToSend = moment(event).format('YYYY-MM-DD');
	}

	prepareDate(stringDate) {
		if (!stringDate) {
			return '';
		} else {
			return moment(stringDate).format('MM/DD/YYYY');
		}
	}

	prepareTime(timeInSeconds) {
		if (timeInSeconds === 30) {
			return (timeInSeconds).toString() + ' ' + 'seconds';
		} else if (timeInSeconds === 60) {
			return (timeInSeconds / 60).toString() + ' ' + 'minute';
		} else {
			return (timeInSeconds / 60).toString() + ' ' + 'minutes';
		}
	}

	createTimeZoneObject() {
		for (const i in this.timezones) {
			this.tmKey = Object.keys(this.timezones[i][0]);
			for (const j in this.tmKey) {
				if (this.tmKey[j].toString() === this.gatewayObj.timezone) {
					this.timeZoneInitials = i;
					this.timezoneToSend = this.tmKey[j];
				}
			}
		}
		if (this.gatewayObj.timezone === 'UTC') {
			this.timezoneToSend = 'UTC';
			this.country = '------------';
		}

		if (this.gatewayObj.timezone !== 'UTC') {
			this.country = this.countriesObject[this.timeZoneInitials];

			this.timeZoneKey = Object.keys(this.timezones[this.timeZoneInitials][0]);

			for (const v in this.timeZoneKey) {
				if (this.timeZoneObjectToDisplay[this.timeZoneInitials] === undefined) {
					this.timeZoneObjectToDisplay[this.timeZoneInitials] = [];
				}
				this.timeZoneObjectToDisplay[this.timeZoneInitials].push({
					name: this.timeZoneKey[v].split('/')[1],
					hours: this.timezones[this.timeZoneInitials][0][this.timeZoneKey[v]].offsetHours,
					minutes: this.timezones[this.timeZoneInitials][0][this.timeZoneKey[v]].offsetMinutes,
					dst: this.timezones[this.timeZoneInitials][0][this.timeZoneKey[v]].isDst,
					timeDelay: this.timezones[this.timeZoneInitials][0][this.timeZoneKey[v]].offset < 0 ? '-' : '+',
					timezone: this.timeZoneKey[v]
				});
			}
		}
	}

	changeTimezone() {
		if (this.country === '------------') {
			this.timeZoneInitials = 'UU';
		} else {
			for (const i in this.countries) {
				if (this.country === this.countriesObject[this.countries[i]]) {
					this.timeZoneInitials = this.countries[i];
					break;
				}
			}
		}

		if (this.timeZoneInitials !== 'UU') {
			this.timeZoneKey = Object.keys(this.timezones[this.timeZoneInitials][0]);
		}

		this.timeZoneObjectToDisplay = {};
		if (this.timeZoneInitials !== 'UU') {
			for (const v in this.timeZoneKey) {
				if (this.timeZoneObjectToDisplay[this.timeZoneInitials] === undefined) {
					this.timeZoneObjectToDisplay[this.timeZoneInitials] = [];
				}
				if (this.timeZoneInitials !== 'UU') {
					this.timeZoneObjectToDisplay[this.timeZoneInitials].push({
						name: this.timeZoneKey[v].split('/')[1],
						hours: this.timezones[this.timeZoneInitials][0][this.timeZoneKey[v]].offsetHours,
						minutes: this.timezones[this.timeZoneInitials][0][this.timeZoneKey[v]].offsetMinutes,
						dst: this.timezones[this.timeZoneInitials][0][this.timeZoneKey[v]].isDst,
						timeDelay: this.timezones[this.timeZoneInitials][0][this.timeZoneKey[v]].offset < 0 ? '-' : '+',
						timezone: this.timeZoneKey[v]
					});
				}
			}
			this.timezoneToSend = this.timeZoneKey[0];
		} else {
			if (this.timeZoneObjectToDisplay[this.timeZoneInitials] === undefined) {
				this.timeZoneObjectToDisplay[this.timeZoneInitials] = [];
			}
			this.timeZoneObjectToDisplay[this.timeZoneInitials].push({
				name: 'UTC',
				hours: 0,
				minutes: 0,
				dst: false,
				timeDelay: '',
				timezone: 'UTC'
			});
			this.timezoneToSend = 'UTC';
		}
	}

	editGateway() {
		if (this.uploadInterval === '30 seconds') {
			this.uploadIntervalToSend = 30;
		} else if (this.uploadInterval === '10 minutes' || this.uploadInterval === '15 minutes') {
			this.uploadIntervalToSend = parseInt(this.uploadInterval.slice(0, 2), 10) * 60;
		} else {
			this.uploadIntervalToSend = parseInt(this.uploadInterval.slice(0, 1), 10) * 60;
		}
		const selectedCompany = this.companies.find(c => c.name === this.companyName);
		this.gatewayObj.companyId = selectedCompany.id;

		const position = this.markerPosition ?? this.mapCenter;

		let promise: Promise<any>;
		if (this.isGateway) {
			const data = {
				'serial': this.gatewayObj.serial,
				'name': this.gatewayObj.name,
				'city': this.gatewayObj.city,
				'latitude': position.lat(),
				'longitude': position.lng(),
				'timezone': this.timezoneToSend,
				'syncTime': this.gatewayObj.syncTime,
				'uploadInterval': this.uploadIntervalToSend,
				'hidden': this.gatewayObj.hidden,
				'subscriptionDate': this.subscriptionDateToSend,
				'installationDate': this.installationDateToSend,
				'assignToCompanyId': this.gatewayObj.companyId
			};

			if (data.city.length < 1 || data.name.length < 3) {
				return;
			}

			promise = this.api.post('/gateways/edit/' + this.id, data).toPromise();
		} else if (this.isNeon1) {
			const data = {
				'serial': this.gatewayObj.serial,
				'city': this.gatewayObj.city,
				'latitude': position.lat(),
				'longitude': position.lng(),
				'timezone': this.timezoneToSend,
				'uploadInterval': this.uploadIntervalToSend,
				'hidden': this.gatewayObj.hidden,
				'subscriptionDate': this.subscriptionDateToSend,
				'installationDate': this.installationDateToSend,
				'assignToCompanyId': this.gatewayObj.companyId
			};

			if (data.city.length < 1) {
				return;
			}

			promise = this.api.post('/n1/gateways/edit/' + this.id, data).toPromise();
		}

		if (promise) {
			this.saveInProgress = true;
			promise
				.then(() => {
					this.router.navigateByUrl('/gateways').then();
				})
				.catch(() => {
					alert('Error saving changes');
				})
				.finally(() => {
					this.saveInProgress = false;
				});
		}
	}

	mapClickHandler(event: google.maps.MapMouseEvent) {
		this.markerPosition = event.latLng;
	}

	markerDragHandler(event: google.maps.MapMouseEvent) {
		this.markerPosition = event.latLng;
	}

	checkSyncTime(syncTime: number) {
		if (syncTime === 0) {
			this.gatewayObj.syncTime = 1;
		} else {
			this.gatewayObj.syncTime = 0;
		}
	}

	checkHidden(hidden: number) {
		if (hidden === 0) {
			this.gatewayObj.hidden = 1;
		} else {
			this.gatewayObj.hidden = 0;
		}
	}

	verifyDescription() {
		this.errorName = this.gatewayObj.name.length < 3;
	}

	verifyCity() {
		this.errorCity = this.gatewayObj.city.length < 1;
	}

	getCompanies() {
		this.api.get('/gateways/companies').toPromise().then(response => {
			this.companies = response;
			Object.values(this.companies).forEach(val => {
				this.companiesNames.push(val.name);
				if (val.id === this.gatewayObj.companyId) {
					this.companyName = val.name;
				}
			});
		});
	}

	searchForAddress() {
		const fullAddress = this.searchLocationField;

		if (fullAddress.length <= 3) {
			return;
		}

		this.nominatimService.addressLookup(fullAddress).toPromise()
			.then((response) => {
				if (response && response[0]) {
					const {latitude, longitude} = response[0];
					this.mapCenter = this.getPositionObject(latitude, longitude);
					this.markerPosition = this.getPositionObject(latitude, longitude);
					this.mapZoomLevel = 16;
				} else {
					this.showAddressError = true;
				}
			})
			.catch((err) => {
				console.error('Got an error from Nominatim API: ' + err);
				this.showAddressError = true;
			});
	}
}
